import { Component, inject, Input, OnChanges } from '@angular/core'
import { TableConfigModel } from "@app/shared/components/table/model";
import {
  addBillPaymentTableKeyConst,
  addBillPaymentTableLabelConst,
} from "@app/pages/financials/shared/const/financial-bill-table-config.const";
import { PaymentStore } from '../../+data-access/payment-store';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TableComponent } from '@app/shared/components/table';

export const sampleData = [
  {
    dueDate:'07/21/2023',
    description:'Bill 123',
    originalAmount:'999.00',
    openBalance:'499.00',
    payment:'499.00'
  }
];

@Component({
  standalone: true,
  imports:[
    CommonModule,
    FormsModule,
    MatCheckboxModule,
    TableComponent
  ],
  selector: 'app-bill-add-payment-table',
  templateUrl: './bill-add-payment-table.component.html',
  styleUrls: ['./bill-add-payment-table.component.scss']
})
export class BillAddPaymentTableComponent implements OnChanges{

  @Input()
  public vendorBills: any[] = sampleData;
  public tableConfig!: TableConfigModel;
  storePayment = inject(PaymentStore);

  ngOnChanges(): void {
    this.initTableConfig()
  }

  get isAllSelected(): boolean {
    const dataTable: any[] = this.tableConfig.dataSource;
    return dataTable.length > 0 ? this.tableConfig.dataSource.every(data => data.isSelected) : false;
  }

  public selectAllData(isChecked: boolean): void {
    this.tableConfig.dataSource.forEach(data=> {
      data.isSelected = isChecked
    });

    this.updateSelectedData();
  }

  private initTableConfig(): void {
    this.tableConfig = new TableConfigModel();
    this.tableConfig.generateDataType();
    this.tableConfig.dataType["payment"] = "custom";
    this.tableConfig.dataType["selection"] = "custom";
    this.tableConfig.dataType["dueDate"] = "M/d/yyyy";
    this.tableConfig.dataType["amountDue"] = "currency";
    this.tableConfig.dataType["openAmount"] = "currency";

    this.tableConfig.dataKey = addBillPaymentTableKeyConst;
    this.tableConfig.dataLabel =  addBillPaymentTableLabelConst;
    this.tableConfig.dataSource = this.vendorBills;
  }

  public selectBill(index: number): void {
    this.tableConfig.dataSource[index].isSelected = true;
  }

  public selectData(): void {
    this.storePayment.updateTotalCalculate(this.tableConfig.dataSource);
  }

  public updateSelectedData(): void {
    this.storePayment.updateTotalCalculate(this.tableConfig.dataSource);
  }

  totalChange(data: any, payment: string): void {
    data.appliedTotal = parseFloat(payment) || 0;
    this.updateSelectedData();
  }
}
