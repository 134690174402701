import { Component, inject, input } from "@angular/core";
import { dialogConfig } from './../../../../../../../../core/const/dialog.const';
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { LogModel } from "@app/pages/log/+data-access";
import { ButtonManageComponent } from "@app/shared/components/button/button-manage/button-manage.component";
import { RoamDialogMdcComponent } from "@app/shared/components/roam-dialog/roam-dialog-mdc/roam-dialog-mdc.component";
import { RoamCardComponent } from "@app/shared/components/roam-layout/roam-card/roam-card.component";
import { RoamLogInfoComponent } from "@app/shared/components/roam-layout/roam-log-activity/roam-log-info/roam-log-info.component";

@Component({
  selector: "activity-log",
  standalone: true,
  imports: [RoamCardComponent, RoamLogInfoComponent, ButtonManageComponent],
  template: `
    <app-roam-card [title]="'Activity Log'">
      <div actRef>
        <button-manage
          icon="eye"
          label="View All"
          (onClick)="viewLogs()
          " />
      </div>
      @if (logs()) {
        <div class="line-list">
          @for (log of logs().slice(0, 5); track log.id) {
            <roam-log-info
              [author]="log.userName"
              [importId]="log.data.importId"
              [name]="log.data.name"
              [verb]="log.verb"
              [createdAt]="log.createdAt" />
          }
        </div>
      } @else {
        <div class="tc-grey font-14">Audit Log Not Available</div>
      }
    </app-roam-card>
  `,
  styles: ``,
})
export class ActivityLogComponent {
  logs = input<LogModel[]>([]);
  readonly dialog = inject(MatDialog);
  

  viewLogs() {
    this.dialog.open(ActivityLogsDialog, {
      ...dialogConfig.formFill,
      data: {
        logs: this.logs()
      }
    })
  }
}

@Component({
  selector: "activity-log",
  standalone: true,
  imports: [RoamDialogMdcComponent, RoamLogInfoComponent],
  template: `
    <app-roam-dialog-mdc
      [title]="'Audit Log'"
      [isAction]="false"
      [close]="true">

      <div dialogBody>
        <div class="line-list">
            @for (log of data.logs; track log.id) {
              <roam-log-info
                [author]="log.userName"
                [importId]="log.data.importId"
                [name]="log.data.name"
                [verb]="log.verb"
                [createdAt]="log.createdAt" />
            }
          </div>
      </div>
    </app-roam-dialog-mdc>
  `,
  styles: ``,
})
export class ActivityLogsDialog {
  readonly data = inject<{logs: LogModel[]}>(MAT_DIALOG_DATA);
}
