import { computed, Injectable, signal, WritableSignal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaymentStore{
  propertyId = signal<string>('');
  paymentId: WritableSignal<string> = signal<string>('');
  payment = signal<any[]>([]);
  totalAmount = signal<number>(0);
  total = signal<number>(0);
  creditBalance = signal<number>(0);
  balance = computed(() => this.totalAmount() - this.total());

  updateTotalCalculate(payments: any[]): any {
    const selectedPayments = payments.filter(payment => payment.isSelected);
    
    const total = selectedPayments.reduce((total, invoice) => {
      const appliedTotal = invoice.appliedTotal || 0;
      return total + appliedTotal;
    }, 0);

    if(this.creditBalance()) {
      this.total.set(this.totalAmount() - this.creditBalance() + total)
    } else {
      this.total.set(total)
    }
  }

  reset(): void {
    this.totalAmount.set(0);
    this.total.set(0);
    this.creditBalance.set(0);
  }
}
