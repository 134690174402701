import { Dialog } from "@angular/cdk/dialog";
import { CommonModule } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  inject,
  input,
  Input,
  OnInit,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { iconLib } from "@app/core/const/roam-icon";
import { DocumentEmailDialogComponent } from "@app/pages/associations/dialog/document-email-dialog";
import { DocumentQueryDialogComponent } from "@app/pages/associations/dialog/document-query-dialog";
import { DocumentShareDialogComponent } from "@app/pages/associations/dialog/document-share-dialog";
import { DocumentPreviewMainComponent } from "@app/pages/document-preview/document-preview-main/document-preview-main.component";
import { DocActionComponent } from "@app/shared/components/document-collection/doc-action/doc-action.component";
import { DocCardComponent } from "@app/shared/components/document-collection/doc-card/doc-card.component";
import { DocAction } from "@app/shared/components/document-collection/shared/const/document.const";
import { getFileIcon } from "@app/shared/components/file-config/shared/utils/file-word-masking.util";
import { ImageViewerComponent } from "@app/shared/components/image-viewer/image-viewer.component";
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";
import { TableComponent } from "@app/shared/components/table";
import { TableConfigModel } from "@app/shared/components/table/model";
import { DocumentViewer } from "@app/shared/interfaces";
import { ByteSizePipe } from "@app/shared/pipes/bytesize.pipe";
import { DocumentService } from "@app/shared/services/document.service";
import { LoadingService } from "@app/shared/services/loading.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { KnowledgeBaseFormDialog } from "../../../../dialog/knowledge-base-form";
import {
  AssociationDocumentTableKeyConst,
  AssociationDocumentTableLabelConst,
} from "../../../../shared/const";
import { IAssociationDocument } from "../../../../shared/interface";
import { DocumentActionFileComponent } from "../document-action-file/document-action-file.component";
import { DocumentActionComponent } from "../document-action/document-action.component";
import { DocumentMobileActionMoreComponent } from "../document-action/document-mobile-action-more/document-mobile-action-more.component";
import { DocumentMobileActionComponent } from "../document-action/document-mobile-action/document-mobile-action.component";

@Component({
  standalone: true,
  imports: [
    ByteSizePipe,
    CommonModule,
    DocActionComponent,
    DocCardComponent,
    DocumentActionComponent,
    DocumentActionFileComponent,
    DocumentMobileActionComponent,
    DocumentMobileActionMoreComponent,
    FormsModule,
    MatCheckboxModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    RoamIconComponent,
    TableComponent,
  ],
  selector: "app-documents-list",
  templateUrl: "./documents-list.component.html",
  styleUrls: ["./documents-list.component.scss"],
})
export class DocumentsListComponent implements OnInit {
  @Input() category!: "house" | null;

  isGlobalSearch = input<boolean>(false);

  @Input("associationInfo")
  public set setAssociationInfo(associationInfo: any) {
    if (!associationInfo.files) return;

    this.associationInfo = associationInfo;

    this.updateTableConfig(associationInfo.files);
  }

  public associationInfo: any;
  public currentPath: string = "";
  public folderList: string[] = [];
  public icon = iconLib;
  public isCardLayout: boolean = false;
  public isLoading!: boolean;
  public isSelectedAll: boolean = false;
  public parentPath: string = "";
  public selectedRow: IAssociationDocument[] = [];
  public showCheckbox: boolean = false;
  public showMore: boolean = false;
  public tableConfig!: TableConfigModel;
  public signalDialog = inject(Dialog);

  constructor(
    private deviceService: DeviceDetectorService,
    private dialog: MatDialog,
    private documentService: DocumentService,
    public loading: LoadingService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.initTableConfig();
  }

  private initTableConfig() {
    this.tableConfig = new TableConfigModel();
    this.tableConfig.dataKey = AssociationDocumentTableKeyConst;
    this.tableConfig.dataLabel = AssociationDocumentTableLabelConst;
  }

  private updateTableConfig(dataList: any[]) {
    this.tableConfig.dataSource = dataList.reduce(
      (result: any[], each: any) => {
        each.date = each.date ? new Date(each.date) : null;
        result.push(each);

        return result;
      },
      []
    );

    this.tableConfig.generateDataType();
    this.tableConfig.dataType["date"] = "date";
    this.tableConfig.dataType["name"] = "custom";
    this.tableConfig.dataType["size"] = "custom";
    this.tableConfig.dataType["selection"] = "custom";
  }

  public onMouseOverChange(document: IAssociationDocument) {
    this.tableConfig.dataSource.forEach(each => {
      each.isHover = each.id === document?.id;
    });
  }

  public onSelectData(document: IAssociationDocument) {
    document.extension ?
      this.openDocument(document)
      : this.openDocumentFolder(document.path);
  }

  public openActionDialog(id: string, data: any): void {
    switch (id) {
      case DocAction.LINK:
        this.openDocumentShareDialog();
        break;
      case DocAction.AI:
        this.openDocumentQueryDialog(data);
        break;
      case DocAction.SHARE:
        this.openDocumentEmailDialog();
        break;
      default:
    }
  }

  private openDocument(element: any) {
    if (element.extension.toLowerCase() === "pdf") {
      let url;
      if (element.isUploaded) {
        url = `https://liberty-30052.s3.amazonaws.com/${element.propertyAbbr}/${element.typeFolder}/${element.diskName}`;
      } else {
        url = element.url;
      }
      const pdfSrc = url;
      this.dialog.open(DocumentPreviewMainComponent, {
        panelClass: "fullpage",
        width: "100vw",
        height: "100vh",
        data: { ...this.setViewer(element, url) },
      });
    } else {
      this.openPreviewer(element.url);
    }
  }

  openPreviewer(url: string): void {
    this.signalDialog.open(ImageViewerComponent, {
      backdropClass: "roam-image-viewer-backdrop",
      panelClass: "roam-image-viewer-panel",
      id: "roamImageViewer",
      data: {
        activeIndex: 0,
        urls: [url],
      },
    });
  }

  setViewer(file: any, url: string): DocumentViewer {
    return {
      title: file.name,
      size: file.size,
      updateAt: file.updatedAt,
      url: file.fileUrl,
    };
  }

  public openDocumentFolder(path: string = "") {
    this.isLoading = true;
    const encodedPath = encodeURIComponent(path);

    this.documentService
      .getFolder(this.associationInfo.id, encodedPath)
      .subscribe(resp => {
        this.currentPath = decodeURIComponent(path);
        this.parentPath = this.currentPath.substring(
          0,
          this.currentPath.lastIndexOf("/")
        );
        this.folderList = this.currentPath.split("/");

        this.updateTableConfig(resp);
        this.breadCrumbsSet();

        this.isLoading = false;
        this.cdr.detectChanges();
      });
  }

  public selectAllData(isChecked: boolean) {
    this.tableConfig.dataSource.forEach(element => {
      element.isSelected = isChecked;
    });

    if (isChecked) {
      this.selectedRow = [];
      this.selectedRow.push(...this.tableConfig.dataSource);
    } else {
      this.selectedRow = [];
    }
  }

  public getIconSource(file: any | undefined): string {
    if (!file.extension) return this.icon.folder.src;
    return getFileIcon(file.extension.toString().toLowerCase());
  }

  public folderBreadcrumbs(path: string = "") {
    if (!path) {
      this.currentPath = "";
    } else if (this.folderList.length > 2) {
      const selectedPath = this.folderList.indexOf(path);
      path = this.folderList.slice(0, selectedPath + 1).join("/");
    }
    this.openDocumentFolder(path);
  }

  private breadCrumbsSet() {
    const breadcrumbs = this.folderList;

    if (breadcrumbs.length > 2) {
      this.folderList = breadcrumbs.slice(-3);
    }
  }

  public selectedRowHandler(row: IAssociationDocument) {
    if (row.isSelected) {
      this.selectedRow.push(row);
      return;
    }

    this.selectedRow = this.selectedRow.filter(t => t.id !== row.id);
    this.isSelectedAll = this.tableConfig.dataSource.every(
      element => element.isSelected
    );
  }

  public resetSelectedRow() {
    this.selectedRow = [];
    this.tableConfig.dataSource.forEach(each => {
      each.isHover = false;
      each.isSelected = false;
    });
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile() || this.deviceService.isTablet();
  }

  public openDocumentShareDialog() {
    this.dialog
      .open(DocumentShareDialogComponent, {
        panelClass: "no-padding-container",
        width: this.isMobile ? "auto" : "55vw",
        maxWidth: "95vw",
        maxHeight: "100vh",
      })
      .afterClosed()
      .subscribe(resp => {
        if (resp) {
          console.log(resp);
        }
      });
  }

  public openDocumentEmailDialog() {
    this.dialog
      .open(DocumentEmailDialogComponent, {
        panelClass: "no-padding-container",
        width: this.isMobile ? "auto" : "55vw",
        maxWidth: "95vw",
        maxHeight: "100vh",
      })
      .afterClosed()
      .subscribe(resp => {
        if (resp) {
          console.log(resp);
        }
      });
  }

  public openDocumentQueryDialog(document: IAssociationDocument) {
    this.dialog
      .open(DocumentQueryDialogComponent, {
        panelClass: "no-padding-container",
        width: this.isMobile ? "auto" : "55vw",
        maxWidth: "95vw",
        maxHeight: "100vh",
        minHeight: "25rem",
        data: {
          element: document,
        },
      })
      .afterClosed()
      .subscribe(resp => {
        if (resp) {
          console.log(resp);
        }
      });
  }

  public openKnowledgeBaseDialog() {
    const documents = this.selectedRow.filter(
      (each: IAssociationDocument) => each.extension
    );

    documents.forEach(each => (each.isSelected = false));

    if (documents.length)
      this.dialog
        .open(KnowledgeBaseFormDialog, {
          data: {
            documents,
          },
          maxHeight: "100vh",
          maxWidth: "95vw",
          panelClass: "no-padding-container",
          width: this.isMobile ? "auto" : "55vw",
        })
        .afterClosed()
        .subscribe(resp => {
          this.selectedRow = [];
        });
  }

  public openActionMobile(key: string, document: IAssociationDocument) {
    if (key === "ai") {
      this.openDocumentQueryDialog(document);
    }
  }

  public switchLayout() {
    this.isCardLayout = !this.isCardLayout;
  }
}
