import { Injectable, inject, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { dialogConfig } from "@app/core/const/dialog.const";
import { GlobalFormDialog } from "@app/shared/dialogs/global-form/container/global-form-dialog";
import { GlobalFormDialogDataModel } from "@app/shared/dialogs/global-form/model";

@Injectable({ providedIn: "root" })
export class AssociationStore {
	associationInfo = signal<any>({});
  readonly #state = {
    services: signal<any[]>([])
  }

  readonly services = this.#state.services.asReadonly();

  #dialog = inject(MatDialog);
  
  updateServices = (service: any) => {
    this.#state.services.update(services => {
      return {...services, ...service}
    })
  }

  public openDialog(menu: string, tab?: string) {
    this.#dialog.open(GlobalFormDialog, {
      ...dialogConfig.formEdit,
      data: {
        data: this.associationInfo,
        menu: menu,
        module: "association",
        tab: tab,
      } as GlobalFormDialogDataModel,
    });
  }
}