<div class="container-dialog-set relativeClass">
  <ng-content select="[container]"></ng-content>
  @if (isLoading) {
    <div class="positionAbsolute">
      <div class="justify-center loader">
        <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
      </div>
    </div>
  }
</div>

<div class="footer-container">
  <span (click)="onClose.emit()" mat-dialog-close class="text-btn-underline fw-500 flex-grow-1">
    Cancel
  </span>

  @if(!hideButton()) {
    <div class="d-flex gap-space">
      @if (!globalStore.isLast()) {
        <app-roam-button
          [class]="'btn-o-primary'"
          [disabled]="globalStore.isSubmit()"
          [label]="buttonLabelSaveAndContinue()"
          (onClick)="onSave.emit('continue')" />
      }

      <app-roam-button
        [disabled]="globalStore.isSubmit()"
        [label]="buttonLabel()"
        (onClick)="onSave.emit('save')" />
    </div>
  }
</div>
