<div
  class="user-action"
  [class.wide]="!pictureOnly()"
  [matMenuTriggerFor]="menu">
  <div class="align-center gap-15 c-pointer">
    <app-image-view
      [width]="'40px'"
      [height]="'40px'"
      [rounded]="true"
      [image]="userInfo.avatarUrl || sampleAvatar" />
    @if (!pictureOnly()) {
      <div class="name-info">
        <div class="name">
          <span>{{ userInfo.name || "N/A" }}</span>
          <app-loader-dot
            *ngIf="!userInfo?.name"
            [light]="true"
            [size]="5"
            [position]="'start'"></app-loader-dot>
        </div>
        <span class="icon">
          <mat-icon class="arrow-down">expand_more</mat-icon>
        </span>
      </div>
    }
  </div>
</div>

<mat-menu #menu="matMenu" class="mat-user-config">
  <button
    mat-menu-item
    class="roam-py-2 roam-px-4"
    (click)="openProfileDialog()">
    <div class="roam-flex roam-items-center roam-h-12">
      <span class="mr-30"></span>
      @if ($any(userInfo)?.name; as name) {
        <span class="name">{{ name }}</span>
      } @else {
        <span>
          <app-loader-dot [size]="5" />
        </span>
      }
    </div>
    <div class="roam-flex roam-items-center">
      <mat-icon>account_circle</mat-icon>&nbsp;
      <span>Profile</span>
    </div>
  </button>
  <button mat-menu-item class="roam-py-2 roam-px-4" (click)="logout()">
    <div class="roam-flex roam-items-center">
      <mat-icon>logout</mat-icon>&nbsp;
      <span>Logout</span>
    </div>
  </button>
</mat-menu>

<ng-template #loading> </ng-template>
