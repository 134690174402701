import { Component, inject, input } from "@angular/core";
import { AssociationStore } from "@app/pages/associations/+data-access/association.store";
import { ButtonManageComponent } from "@app/shared/components/button/button-manage/button-manage.component";
import { ImageViewComponent } from "@app/shared/components/roam-layout/image-view/image-view.component";
import { RoamCardComponent } from "@app/shared/components/roam-layout/roam-card/roam-card.component";
import { TextLabelDescComponent } from "@app/shared/components/roam-layout/text-label-desc/text-label-desc.component";

@Component({
  selector: "app-summary",
  standalone: true,
  imports: [
    RoamCardComponent,
    ButtonManageComponent,
    TextLabelDescComponent,
    ImageViewComponent,
  ],
  template: `
    <app-roam-card [title]="'Summary'">
      <div actRef>
        <button-manage icon="pencil-box" label="Edit" 
          (onClick)="store.openDialog('general')"
        />
      </div>
      <div class="d-flex gap-16">
        <app-image-view
          [image]="associationInfo().imageUrl"
          [width]="'120px'"
          [height]="'90px'"
          placeholderImage="ic-image" />

        <div class="d-grid justify-center-column">
          <app-text-label-desc
            [black]="true"
            [label]="associationInfo().name"
            [desc]="associationInfo().managerName" />
          <a class="link tc-primary font-14px">View on Map</a>
        </div>
      </div>
      <div class="summary-info">
        <div>
          <label>Build Total</label>
          <p>{{ details()?.buildout || "-" }}</p>
        </div>
        <div>
          <label>Incorporation</label>
          <p>{{ details()?.incorporationDate || "-" }}</p>
        </div>
        <div>
          <label>Tax ID</label>
          <p>{{ details()?.FEIN || "-" }}</p>
        </div>
        <div>
          <label>Country</label>
          <p>{{ details()?.county || "-" }}</p>
        </div>
      </div>
    </app-roam-card>
  `,
  styles: `
    .summary-info {
      margin-top: 20px;
      padding: 1rem;
      border-radius: 8px;
      background: rgba(145, 70, 106, 0.06);
      color: #222222;
      display: flex;
      font-size: 14px;

      & > div {
        flex-basis: 25%;
        margin-right: 2rem;
        border-right: 1px solid #ccc;

        label {
          line-height: 2;
          font-weight: 500;
        }

        &:last-child {
          border-right-color: transparent;
        }
      }
    }
  `,
})
export class SummaryComponent {
  readonly associationInfo = input<any>();
  readonly details = input<any>();

  store = inject(AssociationStore);
}
