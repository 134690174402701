export const addBillPaymentTableLabelConst = [
  "Select",
  "Due Date",
  "Ref No",
  "Bill Amount",
  "Open Balance",
  "Payment"
];

export const addBillPaymentTableKeyConst = [
  "selection",
  "dueDate",
  "referenceNumber",
  "amountDue",
  "openAmount",
  "payment"
];

export const billAiTableKey = [
  "name",
  "vendor",
  "amount",
  "dueDate",
  "ref",
  "selection"
]

export const billAiTableLabel = [
  "Name",
  "Vendor",
  "Amount",
  "Due Date",
  "Ref#"
]
