import { CurrencyPipe, DatePipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  OnInit,
  ViewEncapsulation,
  computed,
  effect,
  inject,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { UserConfigStore } from "@app/core/user-config/+data-access";
import {
  AttachmentModel,
  RoamToastrService,
} from "@app/pages/task/+data-access";
import { RoamButtonComponent } from "@app/shared/components/button/roam-button/roam-button.component";
import { RoamToggleSliderComponent } from "@app/shared/components/button/roam-toggle-slider/roam-toggle-slider.component";
import { FileUploaderComponent } from "@app/shared/components/file-uploader";
import { RoamDatepickerComponent } from "@app/shared/components/roam-datepicker/roam-datepicker.component";
import { RoamInputComponent } from "@app/shared/components/roam-input/roam-input.component";
import { RoamSelectComponent } from "@app/shared/components/roam-select/roam-select.component";
import { CustomerService } from "@app/shared/services";
import { AccountingService } from "@app/shared/services/accounting.service";
import { InlineSVGModule } from "ng-inline-svg-2";
import { debounceTime, finalize, forkJoin, merge, tap } from "rxjs";

export interface CustomerInvoiceAddDialogData {
  dialogTitle?: string;
  detail?: any;
}

@Component({
  standalone: true,
  selector: "app-customer-invoice-add-dialog",
  styleUrl: "customer-invoice-add-dialog.component.scss",
  templateUrl: "customer-invoice-add-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe],
  imports: [
    CurrencyPipe,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    InlineSVGModule,
    RoamButtonComponent,
    RoamSelectComponent,
    RoamInputComponent,
    RoamDatepickerComponent,
    FileUploaderComponent,
    RoamToggleSliderComponent,
  ],
})
export class CustomerInvoiceAddDialog implements OnInit {
  #destroyRef = inject(DestroyRef);

  get dialogTitle() {
    return (
      this.data.dialogTitle ??
      (this.data.detail ? "Update Invoice" : "Add New Invoice")
    );
  }

  get detail() {
    return this.data.detail;
  }

  readonly loaders = {
    submitting: signal(false),
  };

  protected selectedAssociationId = signal<string | null>(null);
  protected selectedManagerId = signal<string | null>(null);
  protected attachments = signal<AttachmentModel[]>([]);
  protected modelConfig = computed(() => {
    const modelId = this.detail?.id ?? crypto.randomUUID();
    return {
      modelId,
      model: "invoices",
      propertyId: this.selectedAssociationId()!,
    };
  });

  readonly memoMaxLength = 255;
  readonly totalAmount = signal(0);
  readonly opts = {
    customers: signal<any[]>([]),
    terms: signal<any[]>([]),
    items: signal<any[]>([]),
  };

  readonly form = this.fb.group({
    customerId: this.fb.control("", { validators: Validators.required }),
    reference: this.fb.control("", { validators: Validators.required }),
    termsId: this.fb.control("", { validators: Validators.required }),
    date: this.fb.control(new Date()),
    dueDate: this.fb.control(new Date()),
    memo: this.fb.control(""),
    details: this.fb.array([this.getDetailForm()]),
  });

  protected getDetailForm(value?: any) {
    const formGroup = this.fb.group({
      itemId: this.fb.control(""),
      description: this.fb.control(""),
      quantity: this.fb.control(0),
      rate: this.fb.control(0),
      amount: this.fb.control(0),
    });
    if (value) formGroup.patchValue(value);
    return formGroup;
  }

  fieldError(name: keyof typeof this.form.controls) {
    return this.form.controls[name].invalid && this.form.controls[name].touched;
  }

  get controls() {
    return this.form.controls;
  }

  addDetailRow(): void {
    this.controls.details.push(this.getDetailForm());
  }

  onSave(close = false): void {
    const v = this.form.getRawValue();
    const isEditMode = Boolean(this.detail);
    if (isEditMode && this.detail) {
      this.submitEdit(
        this.detail.id,
        {
          propertyId: this.selectedAssociationId(),
          customerId: v.customerId,
          termsId: v.termsId,
          referenceNumber: v.reference,
          date: v.date,
          dueDate: v.dueDate,
          subtotal: this.totalAmount,
          amount: 0, // TODO: ???
          memo: v.memo || "",
          invoiceDetails: v.details,
        },
        !close
      );
    } else {
      this.submitAdd({
        propertyId: this.selectedAssociationId(),
        customerId: v.customerId,
        termsId: v.termsId,
        referenceNumber: v.reference,
        date: v.date,
        dueDate: v.dueDate,
        subtotal: this.totalAmount,
        amount: 0, // TODO: ???
        memo: v.memo || "",
        invoiceDetails: v.details,
      });
    }
  }

  submitAdd = (body: any) => {
    this.loaders.submitting.set(true);
    this.accountingService
      .createInvoice(body)
      .pipe(
        finalize(() => this.loaders.submitting.set(false)),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe({
        next: resp => {
          console.log("Added", resp);
          this.toastr.success("Succeeded!");
          this.close();
        },
      });
  };

  submitEdit = (id: string, body: any, switchToAdd = false) => {
    this.loaders.submitting.set(true);
    this.accountingService
      .editInvoice(id, body)
      .pipe(
        finalize(() => this.loaders.submitting.set(false)),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe({
        next: resp => {
          this.toastr.success("Succeeded!");
          if (!switchToAdd) {
            this.data.detail = {
              ...this.detail,
              ...resp,
            };
          } else {
            this.close();
          }
        },
      });
  };

  close(newData?: any): void {
    this.dialogRef.close(newData);
  }

  #initAvailableOptions(associationId: string) {
    const customers$ = this.customerService
      .searchCustomers({ propertyId: associationId })
      .pipe(
        tap(resp => {
          const items = resp.data?.filter(x => !!x.name);
          this.opts.customers.set(items);
        })
      );
    const terms$ = this.accountingService
      .getTermsByPropertyId(associationId)
      .pipe(
        tap((resp: any) => {
          this.opts.terms.set(resp || []);
        })
      );
    const items$ = this.accountingService
      .getItemsByPropertyId(associationId)
      .pipe(
        tap(resp => {
          this.opts.items.set(resp || []);
        })
      );
    merge(customers$, terms$, items$)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe();
  }

  patchForm(v: any): void {
    console.log(v);
    if (v.files.length) {
      this.attachments.set(v.files);
    }
    console.warn(v);
    const details = v.invoiceDetails?.map((item: any) => {
      return {
        itemId: item.itemId,
        description: item.description,
        quantity: item.quantity,
        rate: item.rate,
        amount: item.amount,
      };
    });
    this.form.patchValue({
      customerId: v.customerId,
      reference: v.referenceNumber,
      date: v.date,
      memo: v.memo || "",
      details,
    });
  }

  protected onAssociationInit = effect(
    () => {
      const associationId = this.selectedAssociationId();
      if (associationId) {
        this.#initAvailableOptions(associationId);
      }
    },
    { allowSignalWrites: true }
  );

  ngOnInit(): void {
    this.controls.details.valueChanges
      .pipe(debounceTime(200), takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: values => {
          const total = values.reduce((curr, control) => {
            return curr + (control?.amount || 0);
          }, 0);
          this.totalAmount.set(total);
        },
      });
    if (this.detail) {
      console.warn(this.detail);
      this.patchForm(this.detail);
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CustomerInvoiceAddDialogData,
    readonly dialogRef: MatDialogRef<CustomerInvoiceAddDialog>,
    protected fb: NonNullableFormBuilder,
    protected datePipe: DatePipe,
    protected userConfig: UserConfigStore,
    private accountingService: AccountingService,
    private customerService: CustomerService,
    private toastr: RoamToastrService
  ) {
    this.userConfig.selectedAssociationId$
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(id => {
        this.selectedAssociationId.set(id);
      });

    this.userConfig.selectedManagerId$
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(id => {
        this.selectedManagerId.set(id);
      });
  }
}
