import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { RoamButtonComponent } from "../../button/roam-button/roam-button.component";
/*
* Sample: Finance Account List "Add New Account"
* */
@Component({
  standalone: true,
  imports: [MatDialogModule, RoamButtonComponent],
  selector: 'app-roam-dialog-type-one',
  templateUrl: './roam-dialog-type-one.component.html',
  styleUrls: ['./roam-dialog-type-one.component.scss']
})
export class RoamDialogTypeOneComponent {

  @Input()
  public title!: string;

  @Input()
  public isLoading!: boolean;

  @Input()
  public btnText: string = 'Save'

  @Output()
  public onSave: EventEmitter<Event> = new EventEmitter<Event>();
}
