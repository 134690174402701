<div class="roam-table-background borderless table-payment">
  <app-table
    [tableConfig]="tableConfig"
    [emptyRow]="true"
    (selectData)="selectData()"
  >
    <ng-template #selectionTemplate>
      <div class="filter-list">
        <mat-checkbox
          class="check__file"
          [checked]="isAllSelected"
          (change)="selectAllData($event.checked)"
        >
        </mat-checkbox>
      </div>
    </ng-template>

    <ng-template #customTemplate let-key="key" let-data="data">
      <ng-container *ngIf="key === 'payment'">
        <div>
          <input type="text" class="control-input"
            [ngModel]="data.appliedTotal || 0"
            (ngModelChange)="totalChange(data, $event)"
          >
        </div>
      </ng-container>
      <ng-container *ngIf="key === 'selection'">
        <div class="filter-list">
          <mat-checkbox
            [(ngModel)]="data.isSelected"
            (change)="updateSelectedData()"
            class="check__file mnl-6">
          </mat-checkbox>
        </div>
      </ng-container>
    </ng-template>
  </app-table>
</div>
