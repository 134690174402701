import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map, Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class CommitteeFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    address: [[]],
    committee: [[]],
    name: [[]],
    startFrom: [],
    startTo: [],
  });

  formChange$ = this.form.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(values => {
      const filters: any = {}; // object refer to table key

      if (values.address) filters.address = values.address;
      if (values.committee) filters.committee = values.committee;
      if (values.name) filters.name = values.name;
      if (values.startFrom) filters.startFrom = values.startFrom;
      if (values.startTo) filters.startTo = values.startTo;

      return filters;
    })
  );

  address$ = this.form.get("address")?.valueChanges;
  addressOptions$: Subject<{ label: string; value: any }[]> = new Subject();

  committee$ = this.form.get("committee")?.valueChanges;
  committeeOptions$: Subject<{ label: string; value: any }[]> = new Subject();

  name$ = this.form.get("name")?.valueChanges;
  nameOptions$: Subject<{ label: string; value: any }[]> = new Subject();

  startFrom$ = this.form.get("startFrom")?.valueChanges;
  startTo$ = this.form.get("startTo")?.valueChanges;

  constructor() {
    this.getOptions();
  }

  private getOptions() {
    /* TODO | aq | integrate with API */
    setTimeout(() => {
      this.addressOptions$.next([
        { label: "1311 Adcox Square", value: "1311 Adcox Square" },
        { label: "1312 Adcox Square", value: "1312 Adcox Square" },
        { label: "1313 Adcox Square", value: "1313 Adcox Square" },
        { label: "1314 Adcox Square", value: "1314 Adcox Square" },
        { label: "1315 Adcox Square", value: "1315 Adcox Square" },
        { label: "1311 Annslee Circle", value: "1311 Annslee Circle" },
        { label: "1312 Annslee Circle", value: "1312 Annslee Circle" },
        { label: "1313 Annslee Circle", value: "1313 Annslee Circle" },
        { label: "1314 Annslee Circle", value: "1314 Annslee Circle" },
      ]);
      this.committeeOptions$.next([
        { label: "Architectural", value: "Architectural" },
        { label: "Social", value: "Social" },
        { label: "Landscaping", value: "Landscaping" },
      ]);
      this.nameOptions$.next([
        { label: "Aubrey Summers", value: "Aubrey Summers" },
        { label: "Audrey Smith", value: "Audrey Smith" },
        { label: "Beth Berkley", value: "Beth Berkley" },
        { label: "Charice Policronis", value: "Charice Policronis" },
        { label: "Dan McDaniel", value: "Dan McDaniel" },
        { label: "Ethan Duncan", value: "Ethan Duncan" },
        { label: "Florence Bentley", value: "Florence Bentley" },
        { label: "Gloria Clauson", value: "Gloria Clauson" },
        { label: "Harry Fisher", value: "Harry Fisher" },
      ]);
    }, 500);
  }

  reset() {
    this.form.patchValue({
      address: [],
      committee: [],
      name: [],
      startFrom: null,
      startTo: null,
    });
  }
}
