import { CommonModule } from "@angular/common";
import {
  booleanAttribute,
  Component,
  effect,
  inject,
  input,
  OnInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { Router } from "@angular/router";
import { AuthStore } from "@app/core/auth";
import { dialogConfig } from "@app/core/const/dialog.const";
import { UserConfigStore } from "@app/core/user-config/+data-access";
import { AccountProfileModule } from "@app/pages/account-profile/account-profile.module";
import { AccountEditComponent } from "@app/pages/account-profile/pages/account-edit/account-edit.component";
import { accountMenu } from "@app/pages/account-profile/shared/const/account-edit-menu.const";
import { MenuActionService } from "@app/pages/main/shared/services/menu-action.service";
import { ImageService } from "@app/shared/services/image.service";
import { SharedModule } from "@app/shared/shared.module";
import { OAuthService } from "angular-oauth2-oidc";

@Component({
  selector: "app-user-action-menu",
  standalone: true,
  templateUrl: "./user-action-menu.component.html",
  styleUrls: ["./user-action-menu.component.scss"],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    SharedModule,
    AccountProfileModule,
  ],
  providers: [OAuthService],
})
export class UserActionMenuComponent implements OnInit {
  public sampleAvatar = "/assets/sample/avatar.png";
  user = inject(AuthStore).getAuthUser();
  userConfig = inject(UserConfigStore);

  readonly pictureOnly = input(false, { transform: booleanAttribute });

  get userInfo() {
    return this.user;
  }

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private menuAct: MenuActionService,
    private imageService: ImageService
  ) {
    effect(
      () => {
        this.userConfig.setCurrentAssociation(
          this.userInfo.customers?.[0]?.units?.[0]?.propertyId,
          this.userInfo.customers?.[0]?.units?.[0]?.name,
          this.userInfo.customers?.[0]?.units?.[0]?.id,
          this.userInfo.customers?.[0]?.id
        );
      },
      { allowSignalWrites: true }
    );
  }

  ngOnInit() {
    if (this.user) {
      this.imageService.userProfile.next(this.user.avatarUrl);
    }
  }

  public navigateTo(path: string): void {
    this.menuAct.menuActive = -1;
    this.router.navigate([path]);
  }

  public openProfileDialog() {
    this.dialog.open(AccountEditComponent, {
      ...dialogConfig.formEdit,
      data: {
        path: accountMenu.PROFILE,
      },
    });
  }

  #auth = inject(AuthStore);
  logout = () => this.#auth.logout();
}
