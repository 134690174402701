import { Component, forwardRef, Input, input } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from "@angular/forms";
import { MatDatepickerInputEvent, MatDatepickerModule } from "@angular/material/datepicker";
import { CalendarHeaderComponent } from '../calendar-header/calendar-header.component';
import { ROAM_VALIDATORS } from "@app/core/const/validator-error-message.const";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

@Component({
  standalone: true,
  imports:[CommonModule, MatFormFieldModule, MatDatepickerModule, FormsModule, ReactiveFormsModule, MatInputModule],
  selector: 'app-roam-datepicker',
  templateUrl: './roam-datepicker.component.html',
  styleUrls: ['./roam-datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoamDatepickerComponent),
      multi: true
    }
  ]
})
export class RoamDatepickerComponent implements ControlValueAccessor {
  
  floatingLabel = input<boolean>(true);

  @Input() name: string = 'Date';
  calendarHeader = CalendarHeaderComponent;

  @Input() public ctrName: string = '';
  @Input() public ctr: AbstractControl | FormControl | null = null;

  public validators: any = ROAM_VALIDATORS;

  currentValue!: Date;
  onChange: any = () => { }
  onTouch: any = () => { }

  set value(val: any) {
    this.currentValue = val;
    this.onChange(val)
    this.onTouch(val)
  }

  constructor() { }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }

  registerOnTouched(onTouched: Function) {
    this.onTouch = onTouched;
  }

  onValueChange(event: MatDatepickerInputEvent<Date>) {
    this.value = event.value;
  }
}
