import { CurrencyPipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  OnInit,
  inject,
  signal,
} from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import {
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { UserConfigStore } from "@app/core/user-config/+data-access";
import {
  AttachmentModel,
  RoamToastrService,
} from "@app/pages/task/+data-access";
import {
  FileUploaderComponent,
  FileUploaderConfig,
} from "@app/shared/components/file-uploader";
import { RoamDatepickerComponent } from "@app/shared/components/roam-datepicker/roam-datepicker.component";
import { RoamInputComponent } from "@app/shared/components/roam-input/roam-input.component";
import { RoamSelectComponent } from "@app/shared/components/roam-select/roam-select.component";
import { IBill } from "@app/shared/interfaces/accounting.interface";
import { environment } from "@environments/environment";
import { InlineSVGModule } from "ng-inline-svg-2";
import { finalize, merge, tap } from "rxjs";

export interface VendorBillEditDialogData {
  bill?: IBill;
  dialogTitle?: string;
}

@Component({
  standalone: true,
  selector: "app-vendor-bill-edit-dialog",
  styleUrl: "./vendor-bill-edit-dialog.component.scss",
  templateUrl: "./vendor-bill-edit-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    InlineSVGModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatInputModule,
    MatTooltipModule,
    RoamSelectComponent,
    RoamInputComponent,
    RoamDatepickerComponent,
    FileUploaderComponent,
    CurrencyPipe,
  ],
})
export class VendorBillEditDialogComponent implements OnInit {
  #destroyRef = inject(DestroyRef);

  protected isSubmitting = signal(false);

  get bill() {
    return this.data.bill;
  }

  get dialogTitle() {
    return (
      this.data.dialogTitle ??
      (this.bill ? "Edit Vendor Bill" : "Add Vendor Bill")
    );
  }

  protected opts = {
    groups: signal<any[]>([]),
    terms: signal<any[]>([]),
    expenseAccounts: signal<any[]>([]),
    customerAccounts: signal<any[]>([]),
  };

  protected selectedAssociationId = toSignal(
    this.userConfig.selectedAssociationId$,
    { initialValue: this.bill?.propertyId || "" }
  );

  protected selectedManagerId = toSignal(this.userConfig.selectedManagerId$, {
    initialValue: "",
  });

  protected form = this.fb.group({
    groupId: this.fb.control("", { validators: Validators.required }),
    termsId: this.fb.control("", { validators: Validators.required }),
    referenceNumber: this.fb.control("", { validators: Validators.required }),
    date: this.fb.control(new Date()),
    dueDate: this.fb.control(new Date()),
    memo: this.fb.control("", { validators: Validators.maxLength(255) }),
    expenseDetails: this.fb.array([this.generateBillItems()]),
  });

  protected totalItemsAmount = signal(0);

  private generateBillItems(value?: any) {
    return this.fb.group({
      accountId: [value?.accountId ?? ""],
      customerId: [value?.customerId ?? ""],
      isBillable: [value?.isBillable ?? false],
      memo: [value?.memo ?? ""],
      amount: [value?.amount ?? 0],
    });
  }

  protected attachments = {
    data: signal<AttachmentModel[]>([]),
    config: signal<FileUploaderConfig>({
      model: "bills",
      propertyId: "",
      modelId: "",
    }),
  };

  onAddItem(): void {
    this.form.controls.expenseDetails.push(this.generateBillItems());
  }

  onRemoveItem(x: number): void {
    this.form.controls.expenseDetails.removeAt(x);
  }

  onClearItems(): void {
    this.form.controls.expenseDetails.controls.forEach(control => {
      control.patchValue({
        accountId: "",
        customerId: "",
        isBillable: false,
        memo: "",
        amount: 0,
      });
    });
  }

  onSubmit(closeDialog = false): void {
    const propertyId = this.selectedAssociationId();
    if (!propertyId) return;

    const body = {
      propertyId,
      ...this.form.getRawValue(),
    };
    const request$ =
      this.bill ?
        this.httpClient.patch<any>(
          `${environment.apiUrl}/bills/${this.bill.id}`,
          body
        )
      : this.httpClient.post<any>(`${environment.apiUrl}/bills/`, body);
    request$
      .pipe(
        finalize(() => this.isSubmitting.set(false)),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe({
        error: () => {
          this.toastr.danger("Failed to create bill");
        },
        next: resp => {
          console.log("Add succeeded", resp);
          this.toastr.success("Succeeded!");
          closeDialog && this.close(resp);
        },
      });
  }

  close(newBill?: IBill) {
    this.dialogRef.close(newBill);
  }

  #initOptions(propertyId: string): void {
    merge(
      this.httpClient
        .get<any>(`${environment.apiUrl}/vendors/property/${propertyId}`)
        .pipe(
          takeUntilDestroyed(this.#destroyRef),
          tap(resp => {
            this.opts.groups.set(resp || []);
          })
        ),
      this.httpClient
        .get<any>(`${environment.apiUrl}/terms/property/${propertyId}`)
        .pipe(
          tap(resp => {
            this.opts.terms.set(resp || []);
          })
        ),
      this.httpClient
        .get<any>(
          `${environment.apiUrl}/accounts/property/${propertyId}/income-expense`
        )
        .pipe(
          tap(resp => {
            // console.log("Expense Accounts", resp);
            const items = resp?.filter((x: any) => !!x?.name) || [];
            this.opts.expenseAccounts.set(items);
          })
        ),
      this.httpClient
        .post<any>(`${environment.apiUrl}/customer-accounts/search`, {
          property: propertyId,
        })
        .pipe(
          tap(resp => {
            // console.log("CS Accounts", resp);
            const items = resp.data?.filter((x: any) => !!x?.name) || [];
            this.opts.customerAccounts.set(items);
          })
        )
    )
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe();
  }

  onExpenseAccountChange(id: string, index: number) {
    const memo =
      this.opts.expenseAccounts().find(item => item.id === id)?.description ||
      "";
    if (memo) {
      this.form.controls.expenseDetails.controls[
        index
      ].controls.memo.patchValue(memo);
    }
  }

  onAmountChange(): void {
    const total =
      this.form.controls.expenseDetails.value?.reduce((total, v) => {
        // TODO: support multiple currency replacement!
        // but i think the input component should be adjusted instead of doing this manually.
        return total + Number(v.amount?.toString()?.replace("$", ""));
      }, 0) || 0;
    this.totalItemsAmount.set(total);
  }

  #initPatch(bill: IBill): void {
    this.form.patchValue({
      groupId: bill.groupId,
      termsId: bill.termsId,
      referenceNumber: bill.referenceNumber,
      date: new Date(bill.date),
      dueDate: new Date(bill.dueDate),
      memo: bill.memo,
    });
    if (bill.expenseDetails?.length) {
      this.form.controls.expenseDetails.removeAt(0);
      bill.expenseDetails.forEach(item => {
        this.form.controls.expenseDetails.push(this.generateBillItems(item));
      });
    }

    if (bill.files?.length) {
      this.attachments.data.set(bill.files);
    }
  }

  ngOnInit(): void {
    if (this.bill) {
      this.#initPatch(this.bill);
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: VendorBillEditDialogData,
    private dialogRef: MatDialogRef<VendorBillEditDialogComponent>,
    private userConfig: UserConfigStore,
    private fb: NonNullableFormBuilder,
    private toastr: RoamToastrService,
    private httpClient: HttpClient
  ) {
    const propertyId = this.selectedAssociationId() || "";
    this.#initOptions(propertyId);
    this.attachments.config.update(props => {
      return {
        ...props,
        propertyId: propertyId,
        modelId: this.bill?.id || crypto.randomUUID(),
      };
    });
  }
}
