import { IPageType } from "@app/shared/interfaces/page.interface";
import { IModule } from "@app/shared/interfaces";

export type PageName =
  | "accounting"
  | "financials"
  | "reporting"
  | "associations"
  | "listings"
  // | "inbox"
  | "tasks"
  | "contacts"
  // | "calendar"
  | "favorites"
  | "recent"
  | "maintenance"
  | "leasing"
  | "board-portal"
  | "settings-global"
  | "notifications"
  | "communication";

export const subMenus: Record<PageName, Partial<IPageType>[]> = {
  accounting: [
    {
      type: "vendors",
      slug: "bills",
      title: "Bills",
      isGroup: true,
      groupName: "Vendor",
    },
    {
      type: "vendors",
      slug: "bill-payments",
      title: "Bill Payments",
      isGroup: true,
      groupName: "Vendor",
    },
    {
      type: "customers",
      slug: "invoices",
      title: "Invoices",
      isGroup: true,
      groupName: "Customer",
    },
    {
      type: "customers",
      slug: "received-payments",
      title: "Received Payments",
      isGroup: true,
      groupName: "Customer",
    },
    {
      type: "company",
      slug: "accounts",
      title: "Account Lists",
      isGroup: true,
      groupName: "Company",
    },
    {
      type: "company",
      slug: "budgeting",
      title: "Budgeting",
      isGroup: true,
      groupName: "Company",
    },
    {
      type: "company",
      slug: "journal-entries",
      title: "Journal Entries",
      isGroup: true,
      groupName: "Company",
    },
    {
      type: "banking",
      slug: "transactions",
      title: "Transactions",
      isGroup: true,
      groupName: "Banking",
    },
    {
      type: "banking",
      slug: "payable-templates",
      title: "Payable Templates",
      isGroup: true,
      groupName: "Banking",
    },
  ],
  financials: [
    {
      type: "bills",
      title: "Bills",
      isGroup: true,
      groupName: "Vendor",
    },
    {
      type: "bill-payments",
      title: "Bill Payments",
      isGroup: true,
      groupName: "Vendor",
    },
    {
      type: "checks",
      title: "Checks",
      isGroup: true,
      groupName: "Vendor",
    },
    {
      type: "vendor-credits",
      title: "Vendor Credits",
      isGroup: true,
      groupName: "Vendor",
    },
    {
      type: "charges",
      title: "Charges",
      isGroup: true,
      groupName: "Customers",
    },
    {
      type: "credit-memos",
      title: "Credit Memos",
      isGroup: true,
      groupName: "Customers",
    },
    {
      type: "invoices",
      title: "Invoices",
      isGroup: true,
      groupName: "Customers",
    },
    {
      type: "received-payments",
      title: "Received Payments",
      isGroup: true,
      groupName: "Customers",
    },
    {
      type: "journal-entries",
      title: "Journal Entries",
      isGroup: true,
      groupName: "Company",
    },
    {
      type: "accounts",
      title: "Account Lists",
      isGroup: true,
      groupName: "Company",
    },
    {
      type: "budgets",
      title: "Budgeting",
      isGroup: true,
      groupName: "Company",
    },
    {
      type: "deposits",
      title: "Deposits",
      isGroup: true,
      groupName: "Banking",
    },
    {
      type: "transfers",
      title: "Transfers",
      isGroup: true,
      groupName: "Banking",
    },
    {
      type: "payable-templates",
      title: "Payable Templates",
      isGroup: true,
      groupName: "Banking",
    },
  ],
  reporting: [
    // {
    //   type: "reports",
    //   title: "Reports",
    // },
    // {
    //   type: "scheduled-reports",
    //   title: "Scheduled Reports",
    // },
    // {
    //   type: "metrics",
    //   title: "Metrics",
    // },
    // {
    //   type: "surveys",
    //   title: "Surveys",
    // },
    {
      type: "compliance",
      title: "Compliance",
    },
    // {
    //   type: "revenue",
    //   title: "Revenue",
    // },
    // {
    //   type: "bookings",
    //   title: "Bookings",
    // },
    // {
    //   type: "unit-nights",
    //   title: "Unit Nights",
    // },
    {
      type: "reports",
      title: "Reports",
    },
  ],
  associations: [
    {
      type: "",
      title: "All Associations",
    },
  ],
  listings: [
    {
      type: "main",
      title: "Main",
    },
  ],
  tasks: [
    // {
    //   type: "main",
    //   title: "Task Scheduled",
    // },
  ],
  contacts: [
    {
      type: "customers",
      title: "Customer Accounts",
      tableColumns: [
        {
          name: "name",
          title: "Name",
        },
        {
          name: "address",
          title: "Address",
          subArr: "address",
        },
        {
          name: "phones",
          title: "Phone",
          subArr: "number",
        },
        {
          name: "email",
          title: "Email",
        },
      ],
    },
    {
      type: "owners",
      title: "Homeowners",
      tableColumns: [
        {
          name: "name",
          title: "Name",
        },
        {
          name: "role",
          title: "Role",
        },
        {
          name: "address",
          title: "Address",
        },
        {
          name: "email",
          title: "Email",
        },
        {
          name: "phone",
          title: "Phone",
        },
      ],
    },
    {
      type: "tenants",
      title: "Tenants",
      tableColumns: [
        {
          name: "name",
          title: "Name",
        },
        {
          name: "role",
          title: "Role",
        },
        {
          name: "address",
          title: "Address",
        },
        {
          name: "email",
          title: "Email",
        },
        {
          name: "phone",
          title: "Phone",
        },
      ],
    },
    {
      type: "vendors",
      title: "Vendors",
      tableColumns: [
        {
          name: "name",
          title: "Name",
        },
        {
          name: "addresses",
          title: "Address",
          subArr: "address",
        },
        {
          name: "trades",
          title: "Trades",
        },
        {
          name: "phones",
          title: "Phone",
          subArr: "number",
        },
        {
          name: "email",
          title: "Email",
        },
      ],
    },
    {
      type: "employees",
      title: "Employees",
      tableColumns: [
        {
          name: "name",
          title: "Name",
        },
        {
          name: "role",
          title: "Role",
        },
        {
          name: "address",
          title: "Address",
        },
        {
          name: "email",
          title: "Email",
        },
        {
          name: "phone",
          title: "Phone",
        },
      ],
    },
  ],
  favorites: [
    {
      code: "associations",
      name: "Associations",
      slug: "associations",
    },
    {
      code: "financials",
      name: "Accounting",
      slug: "financials",
    },
  ],
  recent: [
    {
      code: "tasks",
      name: "Tasks",
      slug: "tasks",
    },
    {
      code: "reporting",
      name: "Reporting",
      slug: "reporting",
    },
  ],
  maintenance: [
    {
      type: "main",
      title: "Work Orders",
    },
    {
      type: "task-scheduled",
      title: "Tasks",
    },
  ],
  leasing: [
    {
      type: "active",
      title: "Active Leases",
    },
    {
      type: "draft",
      title: "Draft Leases",
    },
    {
      type: "rental",
      title: "Rental Applications",
    },
  ],
  "board-portal": [
    {
      type: "overview", // TODO: extend current overview page
      title: "Overview",
    },
    {
      type: "motion",
      title: "Board Motions",
    },
    {
      type: "meeting", // TODO: custom table!
      title: "Meetings",
    },
    {
      type: "violation", // TODO: custom table!
      title: "Violations",
    },
    {
      type: "architectural",
      title: "Architectural Approval",
    },
    {
      type: "inspection",
      title: "Inspections",
    },
    {
      type: "task",
      title: "Tasks",
    },
    {
      type: "work-order-review",
      title: "Work Order Reviews",
    },
    {
      type: "invoice-approval",
      title: "Invoice Approvals",
    },
    {
      type: "summarized-deliquency",
      title: "Summarized Deliquencies",
    },
    {
      type: "account-receivable",
      title: "Account Receivables",
    },
    {
      type: "customer-account",
      title: "Customer Accounts",
    },
  ],
  "settings-global": [
    {
      type: "organization",
      title: "Organization",
    },
    {
      type: "preferences",
      title: "Preferences",
    },
    {
      type: "billing",
      title: "Billing",
    },
    {
      type: "bank-accounts",
      title: "Bank Accounts",
    },
    {
      type: "permissions",
      title: "Permissions",
    },
    {
      type: "compliance",
      title: "Compliance",
    },
    {
      type: "custom-fields",
      title: "Custom Fields",
    },
    {
      type: "portal-invites",
      title: "Portal Invites",
    },
  ],
  communication: [
    {
      type: "broadcast",
      title: "Broadcast",
    },
    {
      type: "mail-room",
      title: "Mail Room",
    },
    {
      type: "template",
      title: "Templates",
    },
  ],
  notifications: [],
};

export const modulesBottom: IModule[] = [
  {
    id: "",
    name: "Settings",
    slug: "settings-global",
    order: 19,
  },
];

export const submenuContactsForVendor = [
  {
    type: "customers",
    title: "Customers",
  },
  {
    type: "organizations",
    title: "Organizations",
  },
  {
    type: "employees",
    title: "Employees",
  },
];

export const submenuJobsForVendor = [
  {
    type: "jobs",
    title: "Jobs",
  },
  {
    type: "estimates",
    title: "Estimates",
  },
];

export function getSubMenusBySlug(slug: PageName): Partial<IPageType>[] {
  return subMenus[slug] ?? [];
}
