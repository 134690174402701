import { IEmptyState } from "@app/shared/interfaces/empty-state-text.interface";
import { WordDictionary } from "@app/shared/interfaces/word-dictionary.interface";

export const chooseAssociationText = 'Choose your association to get started.';

const financeWordDictionary: WordDictionary= {
  'bill-payments' : 'bill',
  'received-payments': 'payment',
  'vendor-credits' : 'vendor credit'
}

export const generateFinanceEmptyState = (association: boolean = false, type: string, title: string, category: string = ''): IEmptyState => {
  let text: IEmptyState = {title: '',  subtitle: ''};
  if(association) {
    text.title = `No ${title} to Display Yet`;
    text.subtitle = `Choose your ${category ? category : 'association'} to get started.`
  } else {
    text.title = `No ${title} Yet`;
    text.subtitle = `You haven't received any ${financeWordDictionary[type] || type} yet`;
  }

  return text;
}
