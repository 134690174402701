import { CommonModule } from "@angular/common";
import {
  Component,
  DestroyRef,
  inject,
  Inject,
  OnInit,
  signal,
  WritableSignal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { iconLib } from "@app/core/const/roam-icon";
import { convertCurrencyToNumber } from "@app/core/helper/currency.helper";
import { UserConfigStore } from "@app/core/user-config/+data-access";
import { disableFormControl } from "@app/core/utils/form.utils";
import { FinancialsPaymentAppliedComponent } from "@app/pages/financials/components/financials-payment-applied/financials-payment-applied.component";
import { financeWordMask } from "@app/pages/financials/shared/const/finance-word-mask.cons";
import { generateFinanceEmptyState } from "@app/pages/financials/shared/const/financial-empty-state-text.const";
import { RoamToastrService } from "@app/pages/task/+data-access";
import { RoamButtonComponent } from "@app/shared/components/button/roam-button/roam-button.component";
import { EmptyStateComponent } from "@app/shared/components/empty-state/empty-state.component";
import { FileUploaderComponent } from "@app/shared/components/file-uploader";
import { PaymentInformationTypeComponent } from "@app/shared/components/payment-information-type/payment-information-type.component";
import { RoamDatepickerComponent } from "@app/shared/components/roam-datepicker/roam-datepicker.component";
import { RoamInputComponent } from "@app/shared/components/roam-input/roam-input.component";
import { RoamSelectComponent } from "@app/shared/components/roam-select/roam-select.component";
import { AttachmentConfig } from "@app/shared/config/attachments.config";
import { IBillPaymentResponse } from "@app/shared/interfaces/accounting.interface";
import { IDialogData } from "@app/shared/interfaces/dialog-data.interface";
import { IEmptyState } from "@app/shared/interfaces/empty-state-text.interface";
import { PayBill } from "@app/shared/interfaces/pay-invoice.interface";
import {
  SaveType,
  SaveTypeStore,
} from "@app/shared/interfaces/save-type.model";
import { IVendor } from "@app/shared/interfaces/vendor.interface";
import { AccountingService } from "@app/shared/services/accounting.service";
import { VendorService } from "@app/shared/services/vendor.service";
import { PaymentStore } from "../../+data-access/payment-store";
import { BillAddPaymentTableComponent } from "../../components/bill-add-payment-table/bill-add-payment-table.component";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    RoamButtonComponent,
    FinancialsPaymentAppliedComponent,
    BillAddPaymentTableComponent,
    EmptyStateComponent,
    FileUploaderComponent,
    RoamSelectComponent,
    RoamInputComponent,
    RoamDatepickerComponent,
    PaymentInformationTypeComponent,
  ],
  selector: "app-financial-add-bill-payment",
  templateUrl: "./financial-add-bill-payment.component.html",
  styleUrls: ["./financial-add-bill-payment.component.scss"],
})
export class FinancialAddBillPaymentComponent implements OnInit {
  public id!: string;
  public form!: FormGroup;
  public payeeVendorId: string = "";
  public date: string = "";
  public amount: number = 0;
  public referenceNumber: string = "";
  public paymentMethod: string = "";
  public paymentAccount: string = "";
  public memo: string = "";
  public unappliedAmount: number = 0;

  public icon = iconLib;
  public uploadedFiles: File[] = [];
  public selectedVendor: string | null = "";
  public emptyText!: IEmptyState;
  public title: string = "";
  public titleTable: string = "Unpaid Bills";
  selectedAssociationId = signal("");
  public selectedManager: string = "";
  public vendors: IVendor[] = [];
  public loader: { vendor: boolean } = { vendor: false };
  public isLoading: {
    new: WritableSignal<boolean>;
    exit: WritableSignal<boolean>;
  } = {
    new: signal<boolean>(false),
    exit: signal<boolean>(false),
  };
  public selectedPaymentMethod = signal<string | null>(null);
  public vendorBills: any[] = [];
  public paymentAccounts: any[] = [];
  public paymentMethods: any[] = [];

  #destroyRef = inject(DestroyRef);
  storePayment = inject(PaymentStore);
  toastr = inject(RoamToastrService);
  saveType = inject(SaveTypeStore);
  attachmentConfig = inject(AttachmentConfig);

  constructor(
    private userConfig: UserConfigStore,
    private vendorService: VendorService,
    private accountingService: AccountingService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<FinancialAddBillPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData
  ) {
    this.id = data.id;
    this.title = `${data.id ? "Edit" : "Add"} ${financeWordMask[data.type]}`;

    this.attachmentConfig.create(
      this.selectedAssociationId(),
      this.data.id,
      "bill_payments"
    );
  }

  ngOnInit(): void {
    this.initForm();
    this.setEmptyState();
    this.getAssociation();
    this.getVendors();

    this.patchValueBillPayment(this.data.data as IBillPaymentResponse);
  }

  get reference(): boolean {
    const statusShow = ["received-payments"];
    return statusShow.includes(this.data.type);
  }

  private setEmptyState(): void {
    this.emptyText = generateFinanceEmptyState(
      true,
      "bills",
      "Bills",
      "vendor"
    );
  }

  private getAssociation(): void {
    this.userConfig.selectedAssociationId$
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(id => {
        if (id) {
          this.selectedAssociationId.set(id);
        }
      });

    this.userConfig.selectedManagerId$
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(id => {
        this.selectedManager = id || "";
      });
  }

  private getVendors(): void {
    this.loader.vendor = true;
    this.vendorService
      .getVendorsByPropertyId(this.selectedAssociationId())
      .subscribe(resp => {
        this.vendors = resp;
        this.loader.vendor = false;
      });
  }

  public selectVendor(id: string): void {
    this.selectedVendor = id;
    this.storePayment.total.set(0);

    this.getVendorBill(id);
    this.setDetailArrays();
  }

  private initForm(): void {
    this.form = this.fb.group({
      propertyId: [this.selectedAssociationId()],
      payeeVendorId: [null, Validators.required],
      date: [null, Validators.required],
      paymentMethod: [null, Validators.required],
      apAccountId: [null, Validators.required],
      referenceNumber: [null, Validators.required],
      amount: [null, Validators.required],
      memo: [null],
      payBills: this.fb.array([]),
    });

    this.attachmentConfig.attachments.set([]);
  }

  private patchValueBillPayment(data: IBillPaymentResponse): void {
    if (this.data.id) {
      this.selectedVendor = data.transaction.entityId;
      this.getVendorBill(this.selectedVendor);

      this.form.patchValue({
        ...data,
        propertyId: this.selectedAssociationId(),
        payeeVendorId: data.transaction.entityId,
        apAccountId: data.bankAccountId,
        paymentMethod: data.paymentMethodId,
      });
      this.attachmentConfig.attachments.set(data.files);
    }
  }

  private getVendorBill(vendorId: string): void {
    this.accountingService
      .getVendorBills(this.selectedAssociationId(), vendorId)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(data => {
        this.paymentAccounts = data.paymentAccounts;
        this.paymentMethods = data.paymentMethods;

        if (this.data.id) {
          this.vendorBills = this.data.data.appliedDetails;
          disableFormControl(this.form, ["amount"]);
        } else {
          this.vendorBills = data.vendorBills;
        }
      });
  }

  private setDetailArrays(): void {
    const billsFormArray = this.form.controls["payBills"] as FormArray;
    billsFormArray.clear();
    this.vendorBills.forEach(bill => {
      billsFormArray.push(
        this.fb.group({
          billId: bill.id,
          amount: [0, Validators.required],
        })
      );
    });
  }

  public selectedMethod(id: string): void {
    const method =
      this.paymentMethods.find(payment => payment.id === id)?.name || "";
    this.selectedPaymentMethod.set(method);
  }

  public saveBillPayment(type: SaveType = "new"): void {
    if (this.form.valid) {
      this.saveType.setType.set(type);
      this.saveType.isLoading.set(true);

      if (this.id) {
        this.editPayment();
      } else {
        this.savePayment();
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  private savePayment(): void {
    const payload = this.generatePayload();
    this.accountingService
      .createBillPayment(payload)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: () => {
          if (!this.saveType.isSaveNew()) {
            this.dialogRef.close();
            this.accountingService.refresh.next(true);
          } else {
            this.setNewForm();
          }
          this.saveType.isLoading.set(false);
          this.toastr.success("Bill payment has been added!");
        },
        error: () => {
          this.saveType.isLoading.set(false);
        },
      });
  }

  private editPayment(): void {
    const payload = {
      amount: convertCurrencyToNumber(this.form.get("amount")?.value),
    };

    this.accountingService
      .editBillPayment(this.id, payload)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: () => {
          this.dialogRef.close();
          this.accountingService.refresh.next(true);
          this.toastr.success("Bill payment has been updated!");
          this.saveType.isLoading.set(false);
        },
      });
  }

  private setNewForm(): void {
    this.form.reset();
    this.form.markAsUntouched();
    this.vendorBills = [];
    this.selectedVendor = null;
    this.attachmentConfig.attachments.set([]);
    this.storePayment.total.set(0);
  }

  private generatePayload(): any {
    let payload: any;
    payload = this.form.getRawValue();

    payload.propertyId = this.selectedAssociationId();
    payload.payBills = this.payInvoice();
    payload.amount = convertCurrencyToNumber(payload?.amount || 0);
    payload.files = this.attachmentConfig.attachments();

    return payload;
  }

  private payInvoice(): PayBill[] {
    let payBills: PayBill[];

    payBills = this.vendorBills
      .filter(invoice => invoice.isSelected)
      .map(invoice => {
        return {
          billId: invoice.id,
          amount: invoice.appliedTotal,
        };
      });
    return payBills;
  }

  updateTotalAmount(): void {
    const amount = this.form.get("amount")?.value;
    this.storePayment.totalAmount.set(convertCurrencyToNumber(amount));
  }
}
