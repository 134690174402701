import { Component, input, model, output } from '@angular/core';
import { RoamIconComponent } from '../roam-icon/roam-icon.component';
import { ShareActionComponent } from '../share-action/share-action/share-action.component';
import { TableConfigModel } from '../table/model';

@Component({
  standalone: true,
  selector: 'selected-table-action',
  imports: [RoamIconComponent, ShareActionComponent],
  template: `
    @if (isNewUI()) {
      <div class="justify-between w-100">
        <div class="align-center gap-10">
          <roam-icon class="c-pointer" name="close" size="20"
            (onClick)="unselect()"
          />
          <h2>
            @if (count() === 1) {
              <span class="roam-font-semibold">{{ table()?.dataSource?.at(0)?.[key()] }}</span> 
              <span class="tc-grey"> is selected</span>
            } @else {
              <span class="tc-grey"> {{ count() }} {{ name() }}</span>
            }
        </h2>
        </div>
        <share-action />
      </div>
    } @else {
      <div class="justify-between w-100">
        <div class="align-center gap-10">
          <roam-icon class="c-pointer" name="close" size="20"
            (onClick)="unselect()"
          />
          <h2 class="tc-grey"> 
          {{ count() }} {{ name() }}
        </h2>
        </div>
        <share-action />
      </div>
    }
  `,
  styles: ``
})
export class SelectedTableActionComponent {
  table = model<TableConfigModel>();
  name = input<string>();
  isNewUI = input<boolean>(false);
  key = input<string>('');
  onSelected = output<boolean>();

  count(): number {
    return this.table()?.dataSource.filter(data => data.isSelected === true).length || 0
  }

  unselect(): void {
    this.table()?.dataSource.map(data => data.isSelected = false);

    const stateSelect = this.count() === 0;
    this.onSelected.emit(!stateSelect);
  }
}
