import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map, Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class FileFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    createdFrom: [],
    createdTo: [],
    folder: [[]],
    type: [[]],
  });

  formChange$ = this.form.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(values => {
      const filters: any = {}; // object refer to table key

      if (values.createdFrom) filters.createdFrom = values.createdFrom;
      if (values.createdTo) filters.createdTo = values.createdTo;
      if (values.folder) filters.folder = values.folder;
      if (values.type) filters.type = values.type;

      return filters;
    })
  );

  createdFrom$ = this.form.get("createdFrom")?.valueChanges;
  createdTo$ = this.form.get("createdTo")?.valueChanges;

  folder$ = this.form.get("folder")?.valueChanges;
  folderOptions$: Subject<{ label: string; value: any }[]> = new Subject();

  type$ = this.form.get("type")?.valueChanges;
  typeOptions$: Subject<{ label: string; value: any }[]> = new Subject();

  constructor() {
    this.getOptions();
  }

  private getOptions() {
    /* TODO | aq | integrate with API */
    setTimeout(() => {
      this.typeOptions$.next([
        { label: "Documents", value: "Documents" },
        { label: "Images", value: "Images" },
        { label: "PDF", value: "PDF" },
        { label: "Spreadsheets", value: "Spreadsheets" },
      ]);
    }, 500);
  }

  reset() {
    this.form.patchValue({
      createdFrom: null,
      createdTo: null,
      folder: [],
      type: [],
    });
  }
}
