import { FormArray, FormGroup } from "@angular/forms";

export const disableFormControl = (form: FormGroup, exceptControlNames: string[] = []): void => {
    const disableControls = (group: FormGroup | FormArray) => {
      Object.keys(group.controls).forEach(controlName => {
        const control = group.get(controlName);
        if (control instanceof FormGroup || control instanceof FormArray) {
          disableControls(control);
        } else if (!exceptControlNames.includes(controlName)) {
          control?.disable();
        }         
      });
    };
  
    disableControls(form);
  }