import { Component, inject, input, Input } from "@angular/core";
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { PageEvent } from "@angular/material/paginator";
import { TableComponent } from "@app/shared/components/table";
import { TableConfigModel } from "@app/shared/components/table/model";
import { AssociationUnitTableKeyConst, AssociationUnitTableLabelConst } from "@app/pages/associations/shared/const";
import { ComponentChangeDetectionService } from "@app/pages/associations/service/component-change-detection.service";
import { UnitService } from "@app/shared/services/unit.service";
import { PaginationListService } from "@app/shared/services/pagination-list.service";

@Component({
  selector: 'unit-table',
  standalone: true,
  imports: [CommonModule, TableComponent],
  templateUrl: './unit-table.component.html',
  styleUrls: ['./unit-table.component.scss'],
  providers: [PaginationListService],
})
export class UnitTableComponent {
  @Input() category!: 'house' | null;

  isGlobalSearch = input<boolean>(false);
  isLoading = input<boolean>(false);

  tableConfig!: TableConfigModel;

  #router = inject(Router);
  #activatedRoute = inject(ActivatedRoute);
  #changeComponent = inject(ComponentChangeDetectionService)
  #unit = inject(UnitService);
  pagination = inject(PaginationListService);

  @Input("units")
  public set boardDirectors(units: any[]) {
    if (!units) return;

    this.tableConfig = new TableConfigModel();
    this.tableConfig.dataKey = AssociationUnitTableKeyConst;
    this.tableConfig.dataLabel = AssociationUnitTableLabelConst;
    this.tableConfig.dataSource = units;

    this.pagination.setData(units, 10);

    this.tableConfig.generateDataType();
    this.tableConfig.dataType["address"] = "custom";
    this.tableConfig.dataTotal = units.length;
    this.tableConfig.dataSource = this.pagination.getCurrentPageData();
    this.tableConfig.pageSize = 10;
  }

  updatePage(page: PageEvent): void {
    this.pagination.updatePageInfo(page);
    this.tableConfig.dataSource = this.pagination.getCurrentPageData();
  }

  public navigateToUnitDetail(unit: any) {
    this.#changeComponent.associateChange = true;
    this.#unit.unitName.next(unit.name);

    if (this.isGlobalSearch()) {
      this.#router.navigate(['associations/detail/', unit?.property?.slug, 'units', unit.slug], {
        queryParams: {
          tab: '1',
          isDetail: true
        },
      });

      return;
    }

    this.#router.navigate(["units", unit.slug], {
      queryParams: {
        tab: '1',
        isDetail: true
      },
      relativeTo: this.#activatedRoute,
    });
  }
}
