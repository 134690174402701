import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { iconLib } from "@app/core/const/roam-icon";
import { GptService } from "@app/shared/services/gpt.service";
import { InlineSVGModule } from "ng-inline-svg-2";
import { Subscription } from "rxjs";

@Component({
  standalone: true,
  imports:[FormsModule, InlineSVGModule],
  selector: 'app-ask-roam-input',
  templateUrl: './ask-roam-input.component.html',
  styleUrls: ['./ask-roam-input.component.scss']
})
export class AskRoamInputComponent implements OnInit {

  @Input()
  public requestText: string = '';

  @Output()
  public onAppendText: EventEmitter<string> = new EventEmitter<string>();

  public icon = iconLib;
  public command: string = '';
  public isProcessText: boolean = false;
  private subscription: Subscription | undefined;

  constructor(
    private gptService: GptService,
  ) { }

  ngOnInit(): void {}

  public execute(): void {
    const request = this.command
    if (this.requestText.length > 0) {
      this.command += ": " + this.requestText
    }

    this.isProcessText = true;
    this.subscription = this.gptService.askGpt(request).subscribe(response => {
      this.appendText(response.output);
      this.isProcessText = false;
    });
  }

  public appendText(text: string): void {
    this.onAppendText.emit(text)
  }
}
