import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IResponseDTO } from '../interfaces/response.interface';
import { environment } from '@environments/environment';
import { IAddContactRecordReq } from '@app/pages/contacts/shared/interface/add-contact-record-req.interface';
import { IContactInfo } from '../dialogs/global-form/model/contact-info.model';
import { IPets, IVehicles } from '../dialogs/global-form/model';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  http = inject(HttpClient)

  public getContacts(
    property: string = "",
    limit: number = 10,
    page: number = 1,
  ): Observable<IResponseDTO<any[]>> {
    const body = {
      limit,
      page,
    };

    return this.http.post<IResponseDTO<any[]>>(
      `${environment.apiUrl}/contacts/search`,
      body,
      {
        withCredentials: true,
        headers: {
          property,
        },
      },
    );
  }

  public postContactRecord(body: any): Observable<any> {
    return this.http.post<IAddContactRecordReq>(`${environment.apiUrl}/contacts`, body, { withCredentials: true })
  }

  public patchPhones(body: any): Observable<any> {
    return this.http.patch<IContactInfo[]>(`${environment.apiUrl}/phones/batch`, body, { withCredentials: true })
  }

  public patchEmails(body: any): Observable<any> {
    return this.http.patch<IContactInfo[]>(`${environment.apiUrl}/emails/batch`, body, { withCredentials: true })
  }

  public patchPets(body: any): Observable<any> {
    return this.http.patch<IPets[]>(`${environment.apiUrl}/pets/batch`, body, { withCredentials: true })
  }

  public patchVehicles(body: any): Observable<any> {
    return this.http.patch<IVehicles[]>(`${environment.apiUrl}/vehicles/batch`, body, { withCredentials: true })
  }

  public patchContactEmergency(body: any): Observable<any> {
    return this.http.patch<IPets[]>(`${environment.apiUrl}/contact-emergency/batch`, body, { withCredentials: true })
  }
}
