import { AttachmentModel } from '@app/pages/task/+data-access';
import { Injectable, model, signal } from "@angular/core";
import { FileUploaderConfig } from '../components/file-uploader';

@Injectable({
  providedIn: "root",
})
export class AttachmentConfig {
    attachments = signal<AttachmentModel[]>([]);   
    config = signal<FileUploaderConfig>({propertyId: '', modelId: '', model: ''})

    create(propertyId: string, modelId:string, model: string) {
        const config: FileUploaderConfig = {
            propertyId,
            modelId : modelId ? modelId : crypto.randomUUID(),
            model
        }
        
        this.config.set(config);
    }
}
