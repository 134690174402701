<div class="dialog-header">
  <h2 mat-dialog-title>
    {{ dialogTitle }}
  </h2>
  <app-roam-button
    [class]="'btn btn-o-black flat exit-button'"
    [label]="'Save & Exit'"
    [isLoading]="loaders.submitting()"
    [disabled]="loaders.submitting()"
    (onClick)="onSave(true)" />
</div>

<mat-dialog-content class="dialog-content">
  <form [formGroup]="form" class="dialog-form">
    <div class="roam-form-row">
      <div
        class="roam-form-field"
        [class.roam-form-field-error]="fieldError('customerId')">
        <app-roam-select
          style="display: block; width: 320px"
          placeholder="Select Customer Account"
          formControlName="customerId"
          [items]="opts.customers()"
          searchable />
        <p class="roam-form-error-message">This field is required</p>
      </div>
      <div
        class="roam-form-field"
        [class.roam-form-field-error]="fieldError('reference')">
        <app-roam-input name="Reference" formControlName="reference" />
      </div>
      <div
        class="roam-form-field"
        [class.roam-form-field-error]="fieldError('termsId')">
        <app-roam-select
          style="display: block; width: 320px"
          placeholder="Select Terms"
          formControlName="termsId"
          [items]="opts.terms()"
          searchable />
        <p class="roam-form-error-message">This field is required</p>
      </div>
    </div>

    <div class="roam-form-row">
      <app-roam-datepicker name="Bill Date" formControlName="date" />
      <app-roam-datepicker name="Due Date" formControlName="dueDate" />
    </div>

    <div class="details-wrapper" formArrayName="details">
      @for (detail of controls.details.controls; track $index) {
        <div class="roam-form-row alt-row" [formGroupName]="$index">
          <app-roam-select
            placeholder="Item ID"
            formControlName="itemId"
            className="radius-10"
            [items]="opts.items()"
            searchable />

          <app-roam-input name="Description" formControlName="description" />

          <app-roam-input name="Qty" formControlName="quantity" />

          <app-roam-input name="Unit Price" formControlName="rate" />

          <app-roam-input name="Total" formControlName="amount" />

          <button
            mat-icon-button
            class="trash-icon"
            matTooltip="Remove row"
            matTooltipPosition="above"
            [disabled]="$index === 0"
            [style.display]="$index === 0 ? 'none' : 'block'"
            (click)="controls.details.removeAt($index)">
            <span inlineSVG="assets/svg/roam/bin.svg"></span>
          </button>
        </div>
      }
      <div class="roam-form-row">
        <button
          mat-stroked-button
          class="roam-mdc-button-stroked roam-button-primary"
          (click)="addDetailRow()">
          <mat-icon>add</mat-icon>
          <span>Add line</span>
        </button>
        <button mat-button (click)="controls.details.reset()">
          <span class="roam-text fw-400 text-underline">Clear Line Items</span>
        </button>
      </div>
    </div>

    <hr />

    <div class="roam-form-row">
      <div class="memo-wrapper">
        <h3 class="tc-black fw-500 pb-12">Additional Information</h3>
        <mat-form-field appearance="outline">
          <mat-label>Add Memo</mat-label>
          <textarea
            matInput
            formControlName="memo"
            [maxlength]="memoMaxLength"
            placeholder="Add Memo"></textarea>
        </mat-form-field>
        <div class="counter-text">
          {{ controls.memo.value.length || 0 }} / 255
        </div>
      </div>
    </div>

    <div class="roam-form-row">
      <div class="attachments-wrapper roam-w-full">
        <mat-label class="font-14px tc-black fw-500">Attachments</mat-label>
        <app-file-uploader [(data)]="attachments" [config]="modelConfig()" />
      </div>
    </div>

    <div class="mt-20">
      <mat-label class="font-14px tc-black fw-500 roam-w-fit"
        >Sharing</mat-label
      >
      <div class="mt-8 d-grid gap-16">
        <app-roam-toggle-slider
          [label]="'Email a copy to the customer'"
          [labelClass]="'fw-500'"
          [name]="'copyCustomer'" />
        <app-roam-toggle-slider
          [label]="'Email a copy to the management company'"
          [labelClass]="'fw-500'"
          [name]="'copyManagementCompany'" />
      </div>
    </div>

    <p class="balance-text balance-bottom">
      {{ totalAmount() | currency }}
    </p>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <a class="cancel-button" (click)="close()" cdkFocusInitial>
    <span class="label">Cancel</span>
  </a>
  <button mat-flat-button class="confirm-button" (click)="onSave()">
    Save & New
  </button>
</mat-dialog-actions>
