import { Component } from '@angular/core';
import { TextLabelDescComponent } from '../roam-layout/text-label-desc/text-label-desc.component';
import { CurrencyPipe } from '@angular/common';
import { RoamIconComponent } from '../roam-icon/roam-icon.component';

@Component({
  selector: 'payment-information-type',
  standalone: true,
  imports: [
    TextLabelDescComponent,
    CurrencyPipe,
    RoamIconComponent
  ],
  template: `
    <div class="payment-type">
      <div class="align-center gap-8 mb-8">
        <roam-icon name="card" color="primary" size="18"/>
        <h6>Direct Pay</h6>
      </div>

      <div class="set-grid set-grid-3">
        <app-text-label-desc [class]="'std'"
          label="Payment Method"
          [desc]="'ACH'"
        />

        <app-text-label-desc [class]="'std'"
          label="Account Number"
          [desc]="'183932892'"
        />

        <app-text-label-desc [class]="'std'"
          label="Current Balance"
          [desc]="3000 | currency"
        />
      </div>
    </div>
  `,
  styles: `
  .payment-type {
    background-color: #F9F6F8;
    padding: 10px 16px;
    border-radius: 8px;
  }
  `
})
export class PaymentInformationTypeComponent {

}
