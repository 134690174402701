import { Component, input, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageViewComponent } from "@app/shared/components/roam-layout/image-view/image-view.component";
import { HighlightPipe } from '@app/shared/pipes/highlight.pipe';

@Component({
  selector: 'app-text-avatar-label',
  standalone: true,
  imports: [
    CommonModule,
    ImageViewComponent,
    HighlightPipe
  ],
  template: `
    <div class="text-avatar-label align-center gap-12"
         [class.label]="type === 'label'"
         [class.title-label]="type === 'title'"
    >
      <app-image-view [width]="imgHeight+'px'" [height]="imgHeight+'px'"
                      [rounded]="rounded"
                      [noPlaceholder]="true"
                      [image]="image"
                      [placeholderImage]="placeholderImage"
                      [placeholderWidth]="imgHeight+'px'"
      />
      

      <div class="font-14px">
        <div [class]="'ava-label tc-grey mt-4'"
             [class.fw-400]="search()">
          <div [innerHTML]="title | highlight: inputWord()"></div>
        </div>
        <span class="ava-text font-12px tc-dust" *ngIf="showText">
          {{ text }}
        </span>
      </div>
    </div>
  `,
  styles: [
      `
      .text-avatar-label.label.gap-8 {
        gap: 16px!important;
      }

      .text-avatar-label.label .ava-text {
        color: #222222!important;
        font-size: 1rem!important;
        font-weight: 500;
      }

      .title-label .ava-label {
        color: #222222!important;
        font-weight: 500;
        line-height: 1.3rem!important;
      }
    `
  ]
})
export class TextAvatarLabelComponent {

  @Input()
  public image: string  | null= '';

  @Input()
  public title!: string;

  @Input()
  public text!: string;

  @Input()
  public titleClass: string = '';

  @Input()
  public type: 'default' | 'label' | 'title' = 'default';

  @Input()
  public showText: boolean = true;

  @Input()
  public imgHeight: number = 30;

  @Input()
  public rounded: boolean = true;

  @Input()
  public placeholderImage: string = 'user-circle';

  inputWord = input<string | null>(null);
  search = input<boolean>(false);
}
