<div class="tab-container-body">
  <ng-container *ngIf="associationInfo" #tabContainer>
    <div class="mb-18 mt-24">
      <app-roam-swiper-metrics [metrics]="accounts"></app-roam-swiper-metrics>
    </div>
  
    <app-summary 
      [associationInfo]="associationInfo"
      [details]="details"
    />

    <div class="set-grid set-grid-2 stretch">
      
      <activity-log [logs]="logs" />
  
      <app-roam-card [title]="'Unit Types'">
        <div actRef>
          <button-manage icon="eye" label="View All" />
        </div>
        <div
          *ngFor="let item of statistics.unitTypes | keyvalue; let _last = last"
          [ngClass]="!_last ? 'summary__community-border' : 'pt-16'"
          class="summary__community justify-between align-center">
          <p class="summary__community-title">{{ item.key }}</p>
          <div class="align-center">
            <span class="summary__community-number">{{ item.value }}</span>
            <img
              src="/assets/svg/ic-plus.svg"
              alt="Plus Icon"
              class="c-pointer"
              style="margin-left: 16px; width: 20px; vertical-align: middle" />
          </div>
        </div>
      </app-roam-card>
    </div>
  
    <bank-account [bankAccounts]="bankAccounts.data || []" />
  
    <service-amenities [services]="services" />
  
    <file-summary [documents]="associationInfo.favoriteDocuments || []" />
  
    <app-contact-records 
      [contacts]="contacts.data || []"
      [count]="associationInfo?.counts?.contactsCount"
      [unit]="associationInfo?.units?.at(0)"
    />
  
    <upcoming-meeting
      [id]="associationInfo.id"
      [meetings]="meetings.data || []"
      [totalMeetings]="associationInfo?.counts?.meetingsCount" />
  
    <pending-modification-request
      [id]="associationInfo?.id"
      [managerId]="associationInfo.managerId"
      [pendingRequests]="requests.data || []"
      [totalRequests]="associationInfo?.counts?.requestsCount" />
  
    <unresolved-violation
      [id]="associationInfo.id"
      [managerId]="associationInfo.managerId"
      [unresolvedViolations]="violations.data || []"
      [totalViolations]="associationInfo?.counts?.violationsCount" />
  
    <ng-template #emptyState>
      <app-empty-state></app-empty-state>
    </ng-template>
  </ng-container>
  
</div>