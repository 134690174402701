import { Component, effect, inject, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IModule } from "../../../../interfaces";
import { GlobalFormMenuConst } from "../../const/global-form-menu.const";
import { GlobalFormDialogDataModel } from "../../model";
import { GlobalFormDialogService } from "./global-form-dialog.service";
import { GlobalFormStore } from "../../+data-access/global-form.store";

@Component({
  templateUrl: "./global-form.dialog.html",
  styleUrls: ["./global-form.dialog.scss"],
  viewProviders: [GlobalFormDialogService],
})
export class GlobalFormDialog {
  #service = inject(GlobalFormDialogService);
  #globalStore = inject(GlobalFormStore);
  public data?: any;
  public addresses?: any;
  public moduleLabel: string;
  public menu: string;
  public menus: IModule[];
  public module: string;
  public tab?: string;

  protected dialogMenuChanges = effect(() => {
    let nextMenu = this.#service.menuName();

    if (nextMenu) {
      this.menu = nextMenu;
      this.#globalStore.isLast.set(this.menus?.at(-1)?.id === this.menu);
    }
  }, {
    allowSignalWrites: true,
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private config: GlobalFormDialogDataModel
  ) {
    this.data = config.data;
    this.addresses = { ...config.data?.addresses?.at(-1) };

    this.menus = GlobalFormMenuConst[this.config.module] ?? [];

    this.module = config.module;

    this.menu = config.menu ?? this.menus[0]?.id;
    this.tab = config.menu ? config.tab : undefined;

    this.moduleLabel = this.generateTitle(this.module);
  }

  private generateTitle(module: string): string {
    if (module === "customer") return "Customer Account";
    if (module === "owner") return "Homeowner";
    if (module === "unit") return "Unit";

    return module;
  }

  onMenuChange(menuName: string) {
    this.tab = undefined;
    this.#service.setMenuName(menuName);
  }
}
