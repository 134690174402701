import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class TenantFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    address: [],
    contact: [],
    name: [],
    portal: [],
    keyword: [],
  });

  formChange$ = this.form.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(values => {
      const filters: any = {}; // object refer to table key

      if (values.address) filters.address = values.address;
      if (values.contact) filters.contact = values.contact;
      if (values.name) filters.name = values.name;
      if (values.portal) filters.portal = values.portal;
      if (values.keyword) filters.keyword = values.keyword;

      return filters;
    })
  );

  address$ = this.form.get("address")?.valueChanges;
  contact$ = this.form.get("contact")?.valueChanges;
  name$ = this.form.get("name")?.valueChanges;
  portal$ = this.form.get("portal")?.valueChanges;
  keyword$ = this.form.get("keyword")?.valueChanges;

  reset() {
    this.form.patchValue({
      address: null,
      contact: null,
      name: null,
      portal: null,
      keyword: null,
    });
  }
}
