import { Component, Input, OnChanges } from '@angular/core'
import { BaseTableSetComponent } from "@app/core/components/base/base-table-set.component";
import { TableComponent } from '@app/shared/components/table';
import { TableConfigModel } from "@app/shared/components/table/model";

@Component({
  standalone: true,
  imports:[
    TableComponent
  ],
  selector: 'app-financials-payment-applied',
  templateUrl: './financials-payment-applied.component.html',
})
export class FinancialsPaymentAppliedComponent extends BaseTableSetComponent implements OnChanges {
  @Input()
  public dataSource: any[] = [];

  @Input()
  public title: string = 'Applied Payments';

  public tableDataKey: string[] = [
    'date', 'type', 'referenceNumber', 'amount'
  ];

  public tableDataLabel: string[] = [
    'Date', 'Type', 'Ref No', 'Applied Amount'
  ];

  ngOnChanges(): void {
    this.initTable();
  }

  public initTable(): void {
    this.tableConfig = new TableConfigModel();
    this.initTableConfig(this.tableDataKey, this.tableDataLabel);
    this.tableConfig.dataSource = this.dataSource ? this.dataSource : [];
  }
}
