import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'highlight', standalone: true})
export class HighlightPipe implements PipeTransform {
  transform(value: string | null | undefined, searchTerm: string | null | undefined): string {
    if (!searchTerm || !value) return value + '';

    const searchRegEx = new RegExp(searchTerm, 'gi');
    // return value.replace(searchRegEx, match => `<span class="highlight">${match}</span>`);
    return value.replace(searchRegEx, match => `<strong>${match}</strong>`);
  }
}
