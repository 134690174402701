import { computed, Injectable, signal } from "@angular/core";

export type SaveType = 'new' | 'exit';

@Injectable({ providedIn: "root" })
export class SaveTypeStore {
    setType = signal<SaveType>('new');
    isSaveNew = computed(() => this.setType() === 'new');
    isLoading = signal<boolean>(false);

    loadingExit = computed(() => this.setType() === 'exit' && this.isLoading())
    loadingNew = computed(() => this.setType() === 'new' && this.isLoading());
}