import { inject, Injectable, signal, WritableSignal } from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { AuthStore } from "@app/core/auth";
import { TAsyncState } from "@app/pages/task/+data-access";
import { IAssociation, IModule, IUser } from "@app/shared/interfaces";
import { AssociationService } from "@app/shared/services/association.service";
import { ModuleService } from "@app/shared/services/module.service";
import { UserService } from "@app/shared/services/user.service";
import {
  BehaviorSubject,
  combineLatest,
  concat,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from "rxjs";

@Injectable({ providedIn: "root" })
export class UserConfigStore {
  #auth = inject(AuthStore);

  readonly #asyncState = new BehaviorSubject<TAsyncState>("idle");
  readonly #modules = new BehaviorSubject<IModule[]>([]);
  readonly #associations = new BehaviorSubject<IAssociation[]>([]);
  readonly #managers = new BehaviorSubject<IUser[]>([]);
  readonly #selectedManagerId = new BehaviorSubject<string | null>(null);
  readonly #selectedAssociationId = new BehaviorSubject<string | null>(null);

  readonly asyncState$ = this.#asyncState.asObservable();
  readonly modules$ = this.#modules.asObservable();
  readonly managers$ = this.#managers.asObservable();
  readonly associations$ = this.#associations.asObservable();
  readonly selectedManagerId$ = this.#selectedManagerId.asObservable();
  readonly selectedManager$ = combineLatest([
    this.managers$,
    this.selectedManagerId$,
  ]).pipe(map(([list, id]) => list.find(x => x.id === id)));
  readonly selectedAssociationId$ = this.#selectedAssociationId.asObservable();
  readonly selectedAssociation$ = combineLatest([
    this.associations$,
    this.selectedAssociationId$,
  ]).pipe(map(([list, id]) => list.find(x => x.id === id)));
  readonly isLoading$ = this.asyncState$.pipe(map(x => x === "loading"));

  readonly signalSelectedAssociationId = signal<string>("");
  readonly associationAll = signal<IAssociation[]>([])
  readonly currentAssociation:
    WritableSignal<{
      id: string, name: string,
      unit: string, customerId: string
    } | null> = signal(null)

  getModules = () => this.#modules.getValue();

  getSlugs = () => this.getModules().map(x => x.slug);

  setModules = (data: IModule[]): void => this.#modules.next(data);

  getManagers = () => this.#managers.getValue();

  setManagers = (data: IUser[]): void => this.#managers.next(data);

  selectManager = (id: string | null): void => this.#selectedManagerId.next(id);

  getAssociations = () => this.#associations.getValue();

  setAssociations = (data: IAssociation[]): void => {
    this.#associations.next(data);
  };

  setCurrentAssociation = (
    id: string, name: string, unit: string, customerId: string
  ) => {
    this.currentAssociation.set({
      id, name, unit, customerId
    })
  }

  selectAssociation = (id: string | null): void => {
    this.signalSelectedAssociationId.set(id ?? "");
    this.#selectedAssociationId.next(id);
  };

  #init(): void {
    this.#asyncState.next("loading");

    concat(
      this.moduleService.getMyModules().pipe(
        tap(resp => {
          const modules = (resp.modules || []).sort(
            (a, b) => (a.order || 0) - (b.order || 0)
          );
          // TODO: just for testing board portal menu
          // const modules = [
          //   ...(resp.modules || []),
          //   {
          //     icon: null,
          //     id: crypto.randomUUID(),
          //     name: "Board Portal",
          //     order: 1,
          //     slug: "board-portal",
          //   },
          // ].sort((a, b) => (a.order || 0) - (b.order || 0));
          this.setModules(modules);
        })
      ),
      this.userService.getManagers().pipe(
        tap(resp => {
          const managers = resp["users"] || [];
          this.setManagers(managers);
          this.selectManager('');
          /* TODO: the default association is select all */
          // const meInManagers = managers.find(
          //   u => u.id === this.#auth.getAuthUser().id
          // );
          // this.selectManager(meInManagers?.id || null);
        })
      ),
      this.asssociationService.getAssociationsByManager("").pipe(
        tap(resp => {
          this.associationAll.set(resp['properties']);
        })
      ),
      this.selectedManagerId$.pipe(
        distinctUntilChanged(),
        takeUntilDestroyed(),
        switchMap(id => {
          return this.asssociationService
            .getAssociationsByManager(id || "")
            .pipe(
              tap(resp => {
                const list = resp["properties"] || [];

                this.setAssociations(list);
                // this.selectAssociation('');
                /* TODO: the default association is select all */
                // this.selectAssociation(list.at(0)?.id || '');

                // TODO: probably set separate loading state for this one!
                // for now just force loaded if it is not.
                this.#asyncState.next("loaded");
              })
            );
        })
      )
    )
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: () => {
          // this.#asyncState.next("loaded");
        },
        error: () => {
          this.#asyncState.next("error");
        },
      });
  }

  constructor(
    protected userService: UserService,
    protected moduleService: ModuleService,
    protected asssociationService: AssociationService
  ) {
    this.#init();
  }
}
