import { Component, output, input } from "@angular/core";
import { RoamCardComponent } from "../../roam-card/roam-card.component";
import { DatePipe, NgClass, NgForOf } from "@angular/common";
import { CurrencyIntlPipe } from "@app/shared/pipes/currency-amount.pipe";

@Component({
  standalone: true,
  imports: [RoamCardComponent, DatePipe, NgForOf, NgClass, CurrencyIntlPipe],
  selector: "app-roam-log-activity-timeline",
  templateUrl: "./roam-log-activity-timeline.component.html",
  styleUrls: ["./roam-log-activity-timeline.component.scss"],
})
export class RoamLogActivityTimelineComponent {
  logs = input<any[]>([]);
  totalLogs = input<number>(0);

  openEditForm = output<Event>();
}
