<app-roam-dialog-mdc [title]="'Add Contact Record'">
  <ng-container buttonTopRight>
    <app-roam-button
      [class]="'btn btn-o-black tc-black flat'"
      [label]="'Save & Exit'"
      [isLoading]="false"
      (onClick)="onSave()" />
  </ng-container>

  <ng-container dialogBody>
    @defer (when !loaders.unitsLoading()) {
      <div [formGroup]="form" class="dialog-form mt-16">
        
        <div class="roam-form-row">
          <div class="roam-form-field"
            [class.roam-form-field-error]="fieldError('propertyId')">
            <app-roam-select
              style="display: block"
              placeholder="Association"
              formControlName="propertyId"
              [loading]="loaders.propertiesLoading()"
              [items]="opts.properties()"
              searchable />
            <p class="roam-form-error-message">This field is required</p>
          </div>
          <div class="roam-form-field"
            [class.roam-form-field-error]="fieldError('modelId')">
            <app-roam-select
              style="display: block"
              placeholder="Ownership Account"
              formControlName="modelId"
              [loading]="loaders.unitsLoading()"
              [items]="opts.units()"
              searchable />
              <p class="roam-form-error-message">This field is required</p>
          </div>
        </div>
        
        <div class="roam-form-row">
          <div class="roam-form-field">
            <app-roam-select
              style="display: block"
              placeholder="Discussion Description"
              [items]="opts.discussions()" />
          </div>
          <div class="roam-form-field"
          [class.roam-form-field-error]="fieldError('description')">
            <app-roam-input
              name="Subject"
              formControlName="description" />
            <p class="roam-form-error-message">This field is required</p>
          </div>
        </div>
        
        <div class="roam-form-row">
          <div class="roam-form-field"
            [class.roam-form-field-error]="fieldError('date')">
            <app-roam-datepicker 
              name="Date"
              formControlName="date" />
            <p class="roam-form-error-message">This field is required</p>
          </div>
          <div class="roam-form-field">
            <app-roam-select
              style="display: block"
              placeholder="Assigned To"
              formControlName="assignedUserId"
              [items]="opts.assignTo()" />
          </div>
        </div>
        
        <div class="roam-form-row">
          <div class="roam-form-field">
            <!-- <app-roam-select
              style="display: block"
              placeholder="Type"
              formControlName="contactTypeId"
              [items]="opts.type()" /> -->
          </div>
          <div class="roam-form-field">
            <app-roam-select
              style="display: block"
              placeholder="Status"
              formControlName="status"
              [items]="opts.status()" />
          </div>
        </div>
        
        <div class="roam-form-row">
          <div class="roam-form-field"
            [class.roam-form-field-error]="fieldError('text')">
            <app-roam-text-area name="Detailed Notes" formControlName="text" />
            <p class="roam-form-error-message">This field is required</p>
          </div>
        </div>
        <div class="d-flex gap-16 f-column mb-16">
          @for (file of attachments(); track file) {
            <app-file-card-bar
              [name]="file.name"
              [date]="(file.createdAt | date: 'MMMM dd, YYYY') || ''"
              [user]="uploadBy"
              [size]="file.size"
              (onDelete)="deleteFile(file.id)" />
          }
        </div>
  
        <h6 class="title mb-8">Attachments</h6>
        
        <app-file-uploader containerText="Drag Here" [(data)]="attachments" [config]="modelConfig()" [isShowFile]="false" />
  
        <div class="w-25">
          @for (option of copyToOptions; track option) {
            <mat-checkbox class="reverse pr-16 fw-600" 
              [formControlName]="option.control">{{ option.name }}</mat-checkbox>
          }
        </div>
        
      </div>
    } @placeholder {
      <div class="justify-center set-loader-relative" [style.top]="0">
        <mat-progress-spinner color="primary" mode="indeterminate"/>
      </div>
    }
  </ng-container>

  <ng-container actButton>
    <div class="w-100 justify-between">
      <span class="text-underline font-14px c-pointer" mat-dialog-close>Cancel</span>

      <app-roam-button
        [class]="'btn btn-primary bold pl-24 pr-24'"
        [label]="'Create'"
        [isLoading]="false"
        (click)="onSave()" />
    </div>
  </ng-container>
</app-roam-dialog-mdc>
