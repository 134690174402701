<div class="dialog-header">
  <h2 mat-dialog-title>{{ dialogTitle }}</h2>
  <button (click)="onSubmit(true)" class="btn btn-o-primary tc-primary">
    <span>Save & Exit</span>
  </button>
</div>

<mat-dialog-content #dialogContent class="dialog-content">
  <form [formGroup]="form">
    <div class="roam-flex roam-gap-4 roam-w-full roam-mb-4">
      <div class="roam-w-1/3">
        <app-roam-select
          className="radius-10"
          placeholder="Select a vendor account"
          formControlName="groupId"
          [items]="opts.groups()"
          [loading]="false"
          [searchable]="true" />
      </div>
      <div class="roam-w-1/3">
        <app-roam-input name="Reference" formControlName="referenceNumber" />
      </div>
      <div class="roam-w-1/3">
        <app-roam-select
          className="radius-10"
          placeholder="Select Terms"
          formControlName="termsId"
          [items]="opts.terms()"
          [loading]="false"
          [searchable]="true" />
      </div>
    </div>

    <div class="roam-w-full roam-mb-4">
      <div class="roam-mb-4">
        <p class="roam-font-medium tc-black">Bill Details</p>
      </div>
      <div class="roam-flex roam-gap-4">
        <div>
          <app-roam-datepicker name="Bill Date" formControlName="date" />
        </div>
        <div>
          <app-roam-datepicker name="Due Date" formControlName="dueDate" />
        </div>
      </div>
    </div>

    <div class="roam-my-8">
      <div class="roam-mb-4">
        <p class="roam-font-medium tc-black">Item Details</p>
      </div>
      <div
        class="item-details roam-w-full roam-mb-4"
        formArrayName="expenseDetails">
        @for (detail of form.controls.expenseDetails.controls; track $index) {
          <div class="items-form-row" [formGroupName]="$index">
            <app-roam-select
              className="radius-10"
              placeholder="Expense Account"
              formControlName="accountId"
              panelWidth="360px"
              [items]="opts.expenseAccounts()"
              (selectedItem)="onExpenseAccountChange($event, $index)"
              [searchable]="true" />
            <app-roam-select
              className="radius-10"
              formControlName="customerId"
              placeholder="Customer to Bill"
              panelWidth="360px"
              [items]="opts.customerAccounts()"
              [searchable]="true" />
            <div>
              <mat-checkbox
                matTooltip="Billable"
                matTooltipPosition="above"
                formControlName="isBillable" />
            </div>
            <app-roam-input name="Memo" formControlName="memo" />
            <app-roam-input
              name="Amount"
              formControlName="amount"
              (valueChange)="onAmountChange()"
              [currency]="true"
              [maxLength]="10" />
            <button
              mat-icon-button
              class="trash-icon"
              [disabled]="$index === 0"
              [style.opacity]="$index === 0 ? '0' : '100%'"
              [style.cursor]="$index === 0 ? 'none' : 'pointer'"
              matTooltip="Remove item"
              (click)="onRemoveItem($index)">
              <span inlineSVG="assets/svg/trash.svg"></span>
            </button>
          </div>
        }
      </div>
      <div class="roam-w-full roam-flex roam-items-center roam-gap-2">
        <button
          type="button"
          (click)="onAddItem()"
          class="btn btn-o-primary tc-primary">
          <span>Add line</span>
        </button>
        <a (click)="onClearItems()" class="roam-font-medium roam-underline">
          Clear line items
        </a>
      </div>
    </div>

    <div class="roam-w-full roam-mb-4">
      <div class="roam-mb-4">
        <p class="roam-font-medium tc-black">Additional Information</p>
      </div>
      <div class="roam-w-1/2">
        <mat-form-field
          class="roam-form-field roam-w-full"
          appearance="outline">
          <textarea
            matInput
            placeholder="Add Memo"
            formControlName="memo"
            maxLength="255"></textarea>
        </mat-form-field>
        <div class="roam-text-xs roam-text-medium roam-mt-2 roam-text-right">
          {{ form.controls.memo.value.length || 0 }}/255
        </div>
      </div>
    </div>

    <div class="roam-w-full roam-mb-4">
      <div>
        <p class="roam-font-medium tc-black">Attachments</p>
      </div>
      <app-file-uploader
        [(data)]="attachments.data"
        [config]="attachments.config()" />
    </div>

    <div
      class="roam-w-full roam-text-right roam-text-2xl roam-font-medium tc-black">
      <p>Total: {{ totalItemsAmount() | currency }}</p>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions">
  <button mat-button class="close-btn" mat-dialog-close>
    <span class="roam-underline">Close</span>
  </button>

  <button (click)="onSubmit()" class="btn btn-primary tc-white">
    <span>Save</span>
  </button>
</mat-dialog-actions>
