import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  Output,
  computed,
  effect,
  inject,
  input,
  model,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from "@angular/material/paginator";
import { iconLib } from "@app/core/const/roam-icon";
import { ButtonActionComponent } from '@app/shared/components/button/button-action/button-action.component';
import { LabelStatusComponent } from '@app/shared/components/label-status/label-status-icon/label-status.component';
import { RoamIconComponent } from '@app/shared/components/roam-icon/roam-icon.component';
import { RoamCardComponent } from "@app/shared/components/roam-layout/roam-card/roam-card.component";
import { TableComponent } from '@app/shared/components/table';
import { TableConfigModel } from "@app/shared/components/table/model";
import { ContactService } from "@app/shared/services/contact.service";
import { PaginationListService } from "@app/shared/services/pagination-list.service";
import { InlineSVGModule } from 'ng-inline-svg-2';
import { DialogAddContactRecordComponent } from '../dialog-add-contact-record/dialog-add-contact-record.component';
import { dialogConfig } from '@app/core/const/dialog.const';
import { ButtonManageComponent } from '@app/shared/components/button/button-manage/button-manage.component';
import { ButtonMoreComponent } from '@app/shared/components/button/button-more/button-more.component';

@Component({
  standalone: true,
  imports: [
    CommonModule, RoamCardComponent, TableComponent, RoamIconComponent, ButtonMoreComponent,
    InlineSVGModule, ButtonActionComponent, LabelStatusComponent, ButtonManageComponent
  ],
  selector: "app-contact-records",
  templateUrl: "./contact-records.component.html",
  styles: `
    .icon_front {
      padding: 8px;
      border-radius: 10px;
      background-color: rgba(145, 70, 106, 0.1);
    }
    .icon_btn {
      border-radius: 50%;
      border: 1px solid rgba(214, 211, 215, 1);
      display: flex;
      justify-content: center;
      align-items: center;
      aspect-ratio: 1;
      width: 40px;
    }
   
  `,
  providers: [PaginationListService],
})
export class ContactRecordsComponent {
  tableConfig: TableConfigModel = new TableConfigModel();
  icon = iconLib;

  isLoading = signal<boolean>(false);

  id = input<string>();
  contacts = input<any[]>([]);
  count = input<number>(0);
  unit = input([]);
  title = input<string>('Contact Records');


  @Output()
  public actionClick: EventEmitter<string> = new EventEmitter();

  contactService = inject(ContactService);
  dialog = inject(MatDialog);
  pagination = inject(PaginationListService);
  destroyRef = inject(DestroyRef);

  dataKey: string[] = [
    "date",
    "description",
    "contactTypeName",
    "createdUserName",
    "assignedUserName",
    "action",
  ];
  dataLabel: string[] = [
    "Last Updated",
    "Discussion",
    "Subject",
    "Sender",
    "Assigned To",
    "Action",
  ];

  initTable = effect(() => {
    this.tableConfig = this.pagination.setTableConfig(
      this.dataKey,
      this.dataLabel,
      this.contacts()
    );

    this.tableConfig.dataType["date"] = "custom";
    this.tableConfig.dataType["action"] = "custom";
    this.tableConfig.dataTotal = this.count();
    this.tableConfig.dataSource = this.contacts();
  });

  updateTable(page: PageEvent, table: TableConfigModel): void {
    /* this.isLoading.set(true);
    this.pagination.updatePageInfo(page);
    this.contactService
      .getContacts(this.id(), page.pageSize, page.pageIndex + 1)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: resp => {
          this.tableConfig.dataSource = resp.data || [];
          this.tableConfig.dataTotal = resp.meta.total;
          this.isLoading.set(false);
        },
        error: () => {
          this.isLoading.set(false);
        },
      }); */
  }

  addNewContactRecord() {
    this.dialog.open(DialogAddContactRecordComponent, {
      ...dialogConfig.formFill,
      data: {
        unit: this.unit()
      },
    });
  }
}
