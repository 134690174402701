import { IIdNameIcon } from "@app/shared/interfaces/id-name.interface";

export type ActionConfigKeys =
  | "default"
  | "edit"
  | "view"
  | "add-view"
  | "upload"
  | "blast-contact"
  | "email"
  | "file"
  | "user"
  | "unit"
  | "edit-view"
  | "activation"
  | "work-order"
  | "job"
  | "add-letter"
  | "contacts"
  | "deactivate"
  | "vendor-contact-record"
  | "customer-account-detail"
  | "homeowner"
  | "tenant"
  | "vendor"
  | "employee-detail";

export type ActionConfigType = {
  [key in ActionConfigKeys]: IIdNameIcon[];
};

export const actionDefault: IIdNameIcon[] = [
  {
    id: "edit",
    name: "Edit",
    icon: "pencil-box",
  },
  {
    id: "inactive",
    name: "Make Inactive",
    icon: "eye-close",
  },
  {
    id: "delete",
    name: "Delete",
    icon: "bin",
  },
];

export const actionWorkOrder: IIdNameIcon[] = [
  {
    id: "create",
    name: "Create Work Order",
    icon: "add",
  },
  {
    id: "recuring",
    name: "Recuring Work Order",
    icon: "add",
  },
];

export const actionJob: IIdNameIcon[] = [
  {
    id: "create",
    name: "Create Job Order",
    icon: "add",
  },
  {
    id: "recuring",
    name: "Recurring Job Order",
    icon: "add",
  },
];

export const actionEdit: IIdNameIcon[] = [
  {
    id: "edit",
    name: "Edit",
    icon: "pencil-box",
  },
];

export const actionView: IIdNameIcon[] = [
  {
    id: "view",
    name: "View All",
    icon: "eye",
  },
];

export const actionAddView: IIdNameIcon[] = [
  {
    id: "add",
    name: "Add New",
    icon: "add",
  },
  {
    id: "view",
    name: "View All",
    icon: "eye",
  },
];

export const actionEditView: IIdNameIcon[] = [
  {
    id: "edit",
    name: "Edit",
    icon: "pencil-box",
  },
  {
    id: "view",
    name: "View All",
    icon: "eye",
  },
];


export const actionBlastContactRecord: IIdNameIcon[] = [
  {
    id: "board",
    name: "Blast Board",
    icon: "mail",
  },
  {
    id: "committe",
    name: "Blast Committe",
    icon: "mail",
  },
  {
    id: "homeowner",
    name: "Blast Homeowner",
    icon: "mail",
  },
]

export const actionUpload: IIdNameIcon[] = [
  {
    id: "upload",
    name: "Upload File",
    icon: "add",
  },
];

export const actionFile: IIdNameIcon[] = [
  {
    id: "upload",
    name: "Upload File",
    icon: "add",
  },
  {
    id: "switch",
    name: "Switch to List View",
    icon: "switch",
  },
];

export const actionEmail: IIdNameIcon[] = [
  {
    id: "expand",
    name: "Expand All",
    icon: "expand",
  },
  {
    id: "board",
    name: "Email Board",
    icon: "mail",
  },
  {
    id: "committee",
    name: "Email Committee",
    icon: "mail",
  },
  {
    id: "homeowner",
    name: "Email Homeowner",
    icon: "mail",
  },
  {
    id: "reassign",
    name: "Reassing",
    icon: "user-config",
  },
];

export const actionUser: IIdNameIcon[] = [
  {
    id: "add-user",
    name: "Add User",
    icon: "add",
  },
  {
    id: "view",
    name: "View All",
    icon: "eye",
  },
];

export const actionUnit: IIdNameIcon[] = [
  {
    id: "edit",
    name: "Edit",
    icon: "add",
  },
  {
    id: "send",
    name: "Send Activation  Email",
    icon: "share",
  },
  {
    id: "password",
    name: "Password Reset",
    icon: "share",
  },
];

export const actionActivation: IIdNameIcon[] = [
  {
    id: "edit",
    name: "Edit",
    icon: "add",
  },
  {
    id: "edit",
    name: "Send Activation Email",
    icon: "send",
  },
  {
    id: "password",
    name: "Passwowrd Reset",
    icon: "share",
  },
];

export const actionContactHomeOwnerDummy: IIdNameIcon[] = [
  {
    id: "delete",
    name: "Delete",
    icon: "bin",
  },
];

export const actionAddLetter: IIdNameIcon[] = [
  {
    id: "add",
    name: "Add Letter",
    icon: "add",
  },
];

export const actionDeactivate: IIdNameIcon[] = [
  {
    id: "deactivate",
    name: "Deactivate",
    icon: "eye-close",
  },
];
export const vendorContactRecord: IIdNameIcon[] = [
  {
    id: "blastBoard",
    name: "Blast Board",
    icon: "mail",
  },
  {
    id: "blastCommitee",
    name: "Blast Commitee",
    icon: "mail",
  },
  {
    id: "emilHomeOwner",
    name: "Email Home Owner",
    icon: "mail",
  },
];
export const actionCustomerAccountDetail: IIdNameIcon[] = [
  {
    id: "view-violation",
    name: "View Violations",
    icon: "eye",
  },
  {
    id: "view-arc-request",
    name: "View ARC Requests",
    icon: "eye",
  },
  {
    id: "deactivate",
    name: "Deactivate",
    icon: "eye-close",
  },
  {
    id: "delete",
    name: "Delete",
    icon: "bin",
  },
];
export const actionContactHomeOwner: IIdNameIcon[] = [
  {
    id: "chat-homeowner",
    name: "Chat Homeowner",
    icon: "chat",
  },
  {
    id: "send",
    name: "Send Activation Email",
    icon: "send",
  },
  {
    id: "deactivate",
    name: "Deactivate",
    icon: "eye-close",
  },
  {
    id: "delete",
    name: "Delete",
    icon: "bin",
  },
];
export const actionContactTenant: IIdNameIcon[] = [
  {
    id: "chat-tenant",
    name: "Chat Tenant",
    icon: "chat",
  },
  {
    id: "send",
    name: "Send Activation Email",
    icon: "send",
  },
  {
    id: "deactivate",
    name: "Deactivate",
    icon: "eye-close",
  },
  {
    id: "delete",
    name: "Delete",
    icon: "bin",
  },
];
export const actionContactVendor: IIdNameIcon[] = [
  {
    id: "chat-vendor",
    name: "Chat Vendor",
    icon: "chat",
  },
  {
    id: "send",
    name: "Send Activation Email",
    icon: "send",
  },
  {
    id: "deactivate",
    name: "Deactivate",
    icon: "eye-close",
  },
  {
    id: "delete",
    name: "Delete",
    icon: "bin",
  },
];
export const actionContactEmployee: IIdNameIcon[] = [
  {
    id: "chat-employee",
    name: "Chat Employee",
    icon: "chat",
  },
  {
    id: "send",
    name: "Send Activation Email",
    icon: "send",
  },
  {
    id: "deactivate",
    name: "Deactivate",
    icon: "eye-close",
  },
  {
    id: "delete",
    name: "Delete",
    icon: "bin",
  },
];

export const actionConfig: ActionConfigType = {
  "add-view": actionAddView,
  "add-letter": actionAddLetter,
  "blast-contact": actionBlastContactRecord,
  "edit-view": actionEditView,
  "work-order": actionWorkOrder,
  activation: actionActivation,
  default: actionDefault,
  edit: actionEdit,
  email: actionEmail,
  file: actionFile,
  job: actionJob,
  unit: actionUnit,
  upload: actionUpload,
  user: actionUser,
  view: actionView,
  contacts: actionContactHomeOwnerDummy,
  deactivate: actionDeactivate,
  "vendor-contact-record": vendorContactRecord,
  "customer-account-detail": actionCustomerAccountDetail,
  homeowner: actionContactHomeOwner,
  tenant: actionContactTenant,
  vendor: actionContactVendor,
  "employee-detail": actionContactEmployee,
};
