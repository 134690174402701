<div mat-dialog-title class="dialog-header">
  <div class="roam-w-full justify-between">
    <h1 class="font-20px fw-600">
      {{ title }}
    </h1>
    @if (!id && !saveType.loadingNew()) {
      <app-roam-button
        *ngIf="selectedVendor"
        [class]="'btn btn-o-black tc-black flat'"
        [label]="'Save & Exit'"
        [disabled]="saveType.loadingExit()"
        [isLoading]="saveType.loadingExit()"
        (onClick)="saveBillPayment('exit')" />
    }
  </div>
</div>

<div mat-dialog-content class="dialog-content">
  <form [formGroup]="form">
    <div class="set-grid set-grid-3 gap-16 pb-16">
      <app-roam-select
        className="radius-10"
        [searchable]="true"
        formControlName="payeeVendorId"
        placeholder="Select a vendor account"
        [loading]="loader.vendor"
        [items]="vendors"
        [ctrName]="'payeeVendorId'"
        [ctr]="form.get('payeeVendorId')"
        (selectedItem)="selectVendor($event)"></app-roam-select>

      <app-roam-select
        placeholder="Payment Account"
        [items]="paymentAccounts"
        formControlName="apAccountId"
        [ctrName]="'apAccountId'"
        [ctr]="form.get('apAccountId')" />

      <app-roam-select
        placeholder="Payment Method"
        [items]="paymentMethods"
        formControlName="paymentMethod"
        [ctrName]="'paymentMethod'"
        [ctr]="form.get('paymentMethod')"
        (selectedItem)="selectedMethod($event)" />

      <app-roam-datepicker
        name="Payment Date"
        formControlName="date"
        [ctrName]="'date'"
        [ctr]="form.get('date')" />

      <app-roam-input
        name="Ref No"
        formControlName="referenceNumber"
        [ctrName]="'referenceNumber'"
        [ctr]="form.get('referenceNumber')" />

      @if (selectedPaymentMethod() && selectedPaymentMethod() !== "ACH") {
        <app-roam-input
          name="Total Amount"
          formControlName="amount"
          [currency]="true"
          [ctrName]="'amount'"
          [ctr]="form.get('amount')"
          (ngModelChange)="updateTotalAmount()" />
      }
    </div>

    @if (selectedPaymentMethod() === "ACH") {
      <payment-information-type />
    }

    <ng-container *ngIf="selectedVendor; else emptyState">
      <hr />
      <div class="pt-8">
        @if (!id) {
          <h4 class="fw-500 tc-black pb-20">Unpaid Bills</h4>
        }

        <div class="table-invoice-height">
          @if (id) {
            <app-financials-payment-applied [dataSource]="vendorBills" />
          } @else {
            <app-bill-add-payment-table [vendorBills]="vendorBills" />
          }
        </div>
      </div>

      <!-- <hr> -->

      <div class="mt-16">
        <h5 class="fw-500 tc-black pb-10">Additional Information</h5>
        <div class="add-memo">
          <textarea
            placeholder="Add Memo"
            formControlName="memo"
            maxLength="255"></textarea>
          <div class="text-right counter">
            {{ form.get("memo")?.value?.length || 0 }}/255
          </div>
        </div>
        <div>
          <div>
            <div class="pt-18">
              <h6 class="tc-black font-14px fw-500">Attachments</h6>
              <app-file-uploader
                [(data)]="attachmentConfig.attachments"
                [config]="attachmentConfig.config()" />
            </div>
          </div>
        </div>
        <div>
          <div class="f-column align-end justify-end lh-30px pt-30">
            <label>Total</label>

            <div class="font-32px">
              {{ storePayment.total() | currency }}
            </div>

            <div class="tc-dust font-14px">
              (Balance Due:
              <span class="tc-black">
                {{ (storePayment.balance() | currency) || 0 }} </span
              >)
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </form>
</div>

<mat-dialog-actions class="dialog-actions">
  <div class="w-100 justify-between">
    <div mat-dialog-close>
      <span class="text-underline font-14px">Close</span>
    </div>
    <app-roam-button
      *ngIf="selectedVendor"
      [class]="'btn btn-primary bold pl-24 pr-24'"
      [label]="id ? 'Record Payment' : 'Record and New'"
      [isLoading]="saveType.loadingNew()"
      [disabled]="saveType.loadingNew()"
      (click)="saveBillPayment()" />
  </div>
</mat-dialog-actions>

<ng-template #emptyState>
  <div class="pt-50 pb-50">
    <app-empty-state
      [subtitle]="emptyText.subtitle"
      [title]="emptyText.title" />
  </div>
</ng-template>
