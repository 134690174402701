import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { getFileIcon } from "@app/shared/components/file-config/shared/utils/file-word-masking.util";

// @ts-ignore
@Component({
  selector: 'app-file-card-bar',
  template:`
    <div class="file-bar">
      <div class="align-center gap-16" style="flex: 4 0 0">
        <span [inlineSVG]="icon"></span>
        <div class="font-16px fw-500">
          {{ name }}
        </div>
      </div>
      <div class="tc-dust" style="flex: 2 0 0" *ngIf="date || user">
        {{ date | date:'MMMM d, yyyy' }} By {{ user }}
      </div>
      <div class="tc-grey" style="flex: 1 0 0">
        {{ size | bytesize }}
      </div>
      <div style="flex: 0 0 0" (click)="onDelete.emit()">
        <span class="d-flex c-pointer" [inlineSVG]="'./assets/svg/ic-close-ih.svg'" style="fill: #666666"></span>
      </div>
    </div>
  `,
  styles:[
      `
      .file-bar {
        padding: 12px 16px;
        border-radius: 10px;
        border: 1px solid #CCC;
        background: #FFF;
        display: flex;
        align-items: center;
        font-size: 14px;
      }

    `
  ]
})
export class FileCardBarComponent implements OnChanges {

  @Input()
  public name!: string;

  @Input()
  public date!: string;

  @Input()
  public user!: string;

  @Input()
  public size!: number;

  @Input()
  public icon!: string;

  @Output()
  public onDelete: EventEmitter<Event> = new EventEmitter<Event>();

  ngOnChanges(changes: any): void {
    if (changes.name) {
      this.updateIconByExtension();
    }
  }

  private updateIconByExtension() {
    const extension = this.name?.split('.').pop()?.toLowerCase() || '';
    this.icon = getFileIcon(extension);
  }

}
