import { DatePipe } from "@angular/common";
import { Component, input } from "@angular/core";
import { CurrencyIntlPipe } from "@app/shared/pipes/currency-amount.pipe";

@Component({
  selector: "roam-log-info",
  standalone: true,
  imports: [CurrencyIntlPipe, DatePipe],
  template: `
    <div class="tc-black">
      @if (balance()) {
        <span>
          {{ (balance() | currencyIntl) || 0 }}
        </span>
        •
      }

      @if (importId()) {
        <span class="tc-primary fw-500">
          {{ importId() }}
        </span>
        •
      }
      <span>
        {{ name() }}
      </span>
    </div>

    <div class="tc-grey">
      <span class="pr-4 capitalized"> {{ verb() }} : </span>

      <a class="text-underline tc-black">
        {{ author() }}
      </a>
       on
      <span>{{ createdAt() | date: "MM/dd/yyyy" }}</span>
      •
      <span>{{ createdAt() | date: "hh:mm a" }}</span>
    </div>
  `,
  styles: `
    :host {
      font-size: 14px;
    }
  `,
})
export class RoamLogInfoComponent {
  balance = input<number>(0);
  importId = input<string>();
  name = input<string>();
  author = input<string>();
  verb = input<string>();
  createdAt = input<Date | string>();
}
