import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { AbstractInputDirective } from './abstract-input.directive';
import { DebounceInputDirective } from './debounce-input.directive';
import { CurrencyInputDirective } from './currency-input.directive';
import { OnlyNumberDirective } from './only-number.directive';
import { CreditMmYyDirective } from './credit-mm-yy.directive';
import { PhoneMaskDirective } from './phone-mask.directive';



@NgModule({
  declarations: [
    AbstractInputDirective,
    CurrencyInputDirective,
    OnlyNumberDirective,
    CreditMmYyDirective,
    PhoneMaskDirective,
  ],
  imports: [
    CommonModule,
    DebounceInputDirective
  ],
  exports: [
    AbstractInputDirective,
    DebounceInputDirective,
    CurrencyInputDirective,
    OnlyNumberDirective,
    CreditMmYyDirective,
    PhoneMaskDirective,
  ],
  providers: [
    DecimalPipe,
    OnlyNumberDirective
  ]
})
export class InputDirectiveModule { }
