import {
  ConnectionPositionPair,
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayConfig,
} from "@angular/cdk/overlay";
import { ElementRef } from "@angular/core";

export const overlayPositionPairs: ConnectionPositionPair[] = [
  {
    originX: "start",
    originY: "center",
    overlayX: "end",
    overlayY: "center",
    offsetX: -20,
  },
  {
    originX: "start",
    originY: "top",
    overlayX: "end",
    overlayY: "top",
    offsetX: -20,
  },
  {
    originX: "start",
    originY: "bottom",
    overlayX: "end",
    overlayY: "bottom",
    offsetX: -20,
    offsetY: -10,
  },
];

export const overlayPositionCalendar: ConnectionPositionPair[] = [
  {
    originX: "end",
    originY: "top",
    overlayX: "start",
    overlayY: "top",
    offsetX: 4,
  },
  {
    originX: "start",
    originY: "top",
    overlayX: "end",
    overlayY: "top",
    offsetX: -4,
  },
  {
    originX: "start",
    originY: "top",
    overlayX: "start",
    overlayY: "bottom",
  },
];

export const dropdownPosition: ConnectionPositionPair[] = [
  // {
  //   originX: 'end',
  //   originY: 'bottom',
  //   overlayX: 'end',
  //   overlayY: 'top',
  //   offsetY: 8
  // }
  {
    originX: "start",
    originY: "bottom",
    overlayX: "start",
    overlayY: "top",
    offsetY: 12,
  },
  {
    originX: "start",
    originY: "top",
    overlayX: "start",
    overlayY: "bottom",
  },
];

export const dropdownLeftPosition: ConnectionPositionPair[] = [
  {
    originX: "end",
    originY: "bottom",
    overlayX: "end",
    overlayY: "top",
    offsetY: 8,
  },
  {
    originX: "start",
    originY: "top",
    overlayX: "end",
    overlayY: "top",
  },
  {
    originX: "start",
    originY: "bottom",
    overlayX: "end",
    overlayY: "bottom",
  },
];

export const hoverPosition: ConnectionPositionPair[] = [
  {
    originX: "start",
    originY: "bottom",
    overlayX: "start",
    overlayY: "top",
  },
  {
    originX: "start",
    originY: "top",
    overlayX: "start",
    overlayY: "bottom",
  },
];

export const hoverPositionSidebar: ConnectionPositionPair[] = [
  {
    originX: "start",
    originY: "bottom",
    overlayX: "start",
    overlayY: "top",
    offsetX: -20,
    offsetY: 20,
  },
  {
    originX: "start",
    originY: "top",
    overlayX: "start",
    overlayY: "bottom",
    offsetX: -20,
  },
];

export const customOverlay = (
  el: ElementRef,
  overlay: Overlay
): OverlayConfig => {
  const positionStrategy: FlexibleConnectedPositionStrategy = overlay
    .position()
    .flexibleConnectedTo(el) // Connects to the element, not the cursor
    .withPositions([
      {
        originX: "start",
        originY: "top",
        overlayX: "start",
        overlayY: "top",
        offsetY: 30
      },
    ]);

  return {
    hasBackdrop: true,
    backdropClass: "cdk-overlay-transparent-backdrop",
    scrollStrategy: overlay.scrollStrategies.close(),
    width: 300,
    positionStrategy,
  };
};
