import { CommonModule } from '@angular/common';
import { Component, input, signal } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { RoamIconComponent } from '../../roam-icon/roam-icon.component';

@Component({
  standalone: true,
  imports:[
    CommonModule,
    MatDialogModule,
    RoamIconComponent
  ],
  selector: 'app-roam-dialog-mdc',
  templateUrl: './roam-dialog-mdc.component.html',
  styles:`
    .no-action {
      padding-top: 8px!important;
      background-color: #ffffff;
      min-height: unset;
    }
  `
})
export class RoamDialogMdcComponent {

  title = input<string>('');
  close = input<boolean>(false);
  isAction = input<boolean>(true)
}
