<div>
  <div class="font-16px fw-500 tc-black mb-18">{{ title }}</div>
  <div class="roam-table-background borderless table-action-right">
    <app-table
      [httpPagination]="false"
      [tableConfig]="tableConfig"
      [emptyState]="emptyText"
      [emptyRow]="true"
      [isLoading]="isLoading"></app-table>
  </div>
</div>
