import { Component, EventEmitter, input, Input, OnInit, Output } from "@angular/core";
import { LoaderComponent } from "@app/shared/components/roam-loader/loader/loader.component";
import { CommonModule } from "@angular/common";
import { InlineSVGModule } from "ng-inline-svg-2";
import { RoamIconComponent } from "../../roam-icon/roam-icon.component";

@Component({
  standalone: true,
  imports:[
    CommonModule,
    LoaderComponent,
    InlineSVGModule,
    RoamIconComponent
  ],
  selector: 'app-roam-button',
  templateUrl: './roam-button.component.html',
  styles: [`
    button {
      width: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .show {
        opacity: 1 !important;
    }
    .hide {
      opacity: 0 !important;
    }
  `
  ]
})
export class RoamButtonComponent implements OnInit {

  @Input()
  public class: string = 'btn-primary';

  @Input()
  public label: string = '';

  @Input()
  public isLoading: boolean = false;

  @Input()
  public icon: {src: string, alt: string} = {src: '', alt: ''};

  @Input()
  public iconSVG?: string = ''

  @Input()
  public iconClass: string = '';

  @Input()
  public iconHeight: string = '';

  @Input()
  public loader: boolean = false;

  @Input()
  public disabled: boolean | null = false;

  @Input()
  public full: boolean = false;

  @Input()
  public dark: boolean = false;

  @Input()
  _ico!: string;

  @Input()
  _clr!: string;

  @Input()
  _size!: string;

  @Output()
  public onClick: EventEmitter<Event> = new EventEmitter<Event>();


  constructor() { }

  ngOnInit(): void {
  }

  public click(): void {
    this.onClick.emit();
  }

}
