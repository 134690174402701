import { CurrencyPipe, DatePipe } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  OnInit,
  ViewEncapsulation,
  computed,
  effect,
  inject,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { UserConfigStore } from "@app/core/user-config/+data-access";
import {
  AttachmentModel,
  RoamToastrService,
} from "@app/pages/task/+data-access";
import { RoamButtonComponent } from "@app/shared/components/button/roam-button/roam-button.component";
import { FileUploaderComponent } from "@app/shared/components/file-uploader";
import { RoamDatepickerComponent } from "@app/shared/components/roam-datepicker/roam-datepicker.component";
import { RoamInputComponent } from "@app/shared/components/roam-input/roam-input.component";
import { RoamSelectComponent } from "@app/shared/components/roam-select/roam-select.component";
import { CustomerService } from "@app/shared/services";
import { AccountingService } from "@app/shared/services/accounting.service";
import { InlineSVGModule } from "ng-inline-svg-2";
import { finalize, merge, tap } from "rxjs";

export interface CustomerReceivedPaymentAddDialogData {
  dialogTitle?: string;
  detail?: any;
}

@Component({
  standalone: true,
  selector: "app-customer-received-payment-add-dialog",
  styleUrl: "customer-received-payment-add-dialog.component.scss",
  templateUrl: "customer-received-payment-add-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe],
  imports: [
    CurrencyPipe,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatTooltipModule,
    InlineSVGModule,
    RoamButtonComponent,
    RoamSelectComponent,
    RoamInputComponent,
    RoamDatepickerComponent,
    FileUploaderComponent,
  ],
})
export class CustomerReceivedPaymentAddDialog implements AfterViewInit, OnInit {
  #destroyRef = inject(DestroyRef);

  get dialogTitle() {
    return (
      this.data.dialogTitle ??
      (this.data.detail ? "Update Received Payment" : "Add Received Payment")
    );
  }

  get detail() {
    return this.data.detail;
  }

  readonly loaders = {
    submitting: signal(false),
  };

  protected selectedAssociationId = signal<string | null>(null);
  protected selectedManagerId = signal<string | null>(null);
  protected attachments = signal<AttachmentModel[]>([]);
  protected modelConfig = computed(() => {
    const modelId = this.detail?.id ?? crypto.randomUUID();
    return {
      modelId,
      model: "received_payments",
      propertyId: this.selectedAssociationId()!,
    };
  });

  readonly memoMaxLength = 255;
  readonly totalAmount = signal(0);
  readonly opts = {
    customers: {
      data: signal<any[]>([]),
      isLoading: signal(false),
    },
    paymentAccounts: {
      data: signal<any[]>([]),
      isLoading: signal(false),
    },
    paymentMethods: {
      data: signal<any[]>([]),
      isLoading: signal(false),
    },
  };

  readonly invoices = {
    unpaid: signal<any[]>([]),
    paid: signal<any[]>([]),
  };

  readonly form = this.fb.group({
    customerId: this.fb.control("", { validators: Validators.required }),
    depositAccountId: this.fb.control("", { validators: Validators.required }),
    paymentMethodId: this.fb.control("", { validators: Validators.required }),
    date: this.fb.control(new Date(), { validators: Validators.required }),
    referenceNumber: this.fb.control("", { validators: Validators.required }),
    totalAmount: this.fb.control(0),
    memo: this.fb.control(""),
  });

  fieldError(name: keyof typeof this.form.controls) {
    return this.form.controls[name].invalid && this.form.controls[name].touched;
  }

  get controls() {
    return this.form.controls;
  }

  onSave(close = false): void {
    // TODO: adjust later! block request for now
    if (true) return;
    const v = this.form.getRawValue();
    const isEditMode = Boolean(this.detail);
    if (isEditMode && this.detail) {
      this.submitEdit(
        this.detail.id,
        {
          propertyId: this.selectedAssociationId(),
          customerId: v.customerId,
          referenceNumber: v.referenceNumber,
          date: v.date,
          subtotal: this.totalAmount,
          amount: 0, // TODO: ???
          memo: v.memo || "",
        },
        !close
      );
    } else {
      this.submitAdd({
        propertyId: this.selectedAssociationId(),
        customerId: v.customerId,
        referenceNumber: v.referenceNumber,
        date: v.date,
        subtotal: this.totalAmount,
        amount: 0, // TODO: ???
        memo: v.memo || "",
      });
    }
  }

  private submitAdd = (body: any) => {
    this.loaders.submitting.set(true);
    this.accountingService
      .createReceivedPayment(body)
      .pipe(
        finalize(() => this.loaders.submitting.set(false)),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe({
        next: resp => {
          console.log("Added", resp);
          this.toastr.success("Succeeded!");
          this.close();
        },
      });
  };

  private submitEdit = (id: string, body: any, switchToAdd = false) => {
    this.loaders.submitting.set(true);
    this.accountingService
      .editReceivedPayment(id, body)
      .pipe(
        finalize(() => this.loaders.submitting.set(false)),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe({
        next: resp => {
          this.toastr.success("Succeeded!");
          if (!switchToAdd) {
            this.data.detail = {
              ...this.detail,
              ...resp,
            };
          } else {
            this.close();
          }
        },
      });
  };

  close(newData?: any): void {
    this.dialogRef.close(newData);
  }

  private loadCustomerInvoices(customerId: string): void {
    this.opts.paymentMethods.isLoading.set(true);
    this.accountingService
      .getOpenInvoices(customerId)
      .pipe(
        finalize(() => this.opts.paymentMethods.isLoading.set(false)),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe({
        next: data => {
          const unpaid = data.unpaidInvoices || [];
          const methods = data.paymentMethods || [];
          this.invoices.unpaid.set(unpaid);
          this.opts.paymentMethods.data.set(methods);
          if (methods?.[0]?.id) {
            this.controls.paymentMethodId.patchValue(methods[0].id);
          }
        },
      });
  }

  #initAvailableOptions(associationId: string) {
    this.opts.customers.isLoading.set(true);
    this.opts.paymentAccounts.isLoading.set(true);
    const customers$ = this.customerService.getAllCustomers(associationId).pipe(
      finalize(() => this.opts.customers.isLoading.set(false)),
      tap(resp => {
        this.opts.customers.data.set(resp.data || []);
      })
    );

    const paymentAccounts$ = this.accountingService
      .getUndepositedFunds(associationId)
      .pipe(
        finalize(() => this.opts.paymentAccounts.isLoading.set(false)),
        tap((resp: any) => {
          this.opts.paymentAccounts.data.set(resp || []);
        })
      );

    const paymentMethods$ = this.accountingService
      .getPaymentMethods(associationId)
      .pipe(
        finalize(() => this.opts.paymentAccounts.isLoading.set(false)),
        tap((resp: any) => {
          this.opts.paymentMethods.data.set(resp || []);
        })
      );

    merge(customers$, paymentAccounts$, paymentMethods$)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe();
  }

  patchForm(v: any): void {
    console.log("PATCHING FORM » ", v);
    if (v.files.length) {
      this.attachments.set(v.files);
    }
    this.form.patchValue({
      customerId: v.customerId,
      referenceNumber: v.referenceNumber,
      depositAccountId: v.depositAccountId,
      paymentMethodId: v.paymentMethodId,
      date: v.date,
      memo: v.memo || "",
    });
  }

  protected onAssociationInit = effect(
    () => {
      const associationId = this.selectedAssociationId();
      if (associationId) {
        this.#initAvailableOptions(associationId);
      }
    },
    { allowSignalWrites: true }
  );

  ngAfterViewInit(): void {
    this.controls.customerId.valueChanges
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: customerId => {
          if (customerId) {
            this.loadCustomerInvoices(customerId);
          }
        },
      });
  }

  ngOnInit(): void {
    if (this.detail) {
      this.patchForm(this.detail);
      // TODO: verity every fields!
      this.invoices.paid.set(this.detail.appliedDetails || []);
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CustomerReceivedPaymentAddDialogData,
    readonly dialogRef: MatDialogRef<CustomerReceivedPaymentAddDialog>,
    protected fb: NonNullableFormBuilder,
    protected datePipe: DatePipe,
    protected userConfig: UserConfigStore,
    private customerService: CustomerService,
    private accountingService: AccountingService,
    private toastr: RoamToastrService
  ) {
    this.userConfig.selectedAssociationId$
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(id => {
        this.selectedAssociationId.set(id);
      });

    this.userConfig.selectedManagerId$
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(id => {
        this.selectedManagerId.set(id);
      });
  }
}
