import { WordDictionary } from "@app/shared/interfaces/word-dictionary.interface";

export const financeWordMask: WordDictionary = {
  'journal-entries': 'Journal Entry',
  'transfers': 'Account Transfer',
  'charges': 'Customer Charge',
  'deposits': 'Bank Deposit',
  'credit-memos': 'Customer Credit Memo',
  'checks': 'Expense Check',
  'bills' : 'Vendor Bill',
  'bill-payments': 'Vendor Bill Payment',
  'invoices': 'Customer Invoice',
  'received-payments': 'Receive Customer Payment',
  'vendor-credits': 'Vendor Credit'
}
