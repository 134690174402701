import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { NotificationModel } from "./notification.model";

type Resp<Model = any> = {
  data: Model;
  total?: number;
};

type GetManyParams = {
  page: number;
  size: number;
};

@Injectable({ providedIn: "root" })
export class NotificationService {
  #http = inject(HttpClient);

  get baseUrl() {
    return `${environment.apiUrl}/notification`;
  }

  test() {
    return this.#http.post<Resp<NotificationModel>>(`${this.baseUrl}/test`, {});
  }

  getMany<M = NotificationModel[]>(params: GetManyParams) {
    return this.#http.get<Resp<M>>(this.baseUrl, { params });
  }

  getOne<M = NotificationModel>(id: string) {
    return this.#http.get<Resp<M>>(`${this.baseUrl}/${id}/read`);
  }

  addOne<M = NotificationModel>(body: any) {
    return this.#http.post<Resp<M>>(this.baseUrl, body);
  }

  removeOne(id: string) {
    return this.#http.post(this.baseUrl, { id });
  }
}
