import { TableConfigModel } from "@app/shared/components/table/model";
import { IEmptyState } from "@app/shared/interfaces/empty-state-text.interface";
import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { PageEvent } from "@angular/material/paginator";
import { iconLib } from "@app/core/const/roam-icon";
import { IAssociationDocument } from "@app/pages/associations/shared/interface";
import { isMobileOrTablet } from "@app/core/const/responsive";

@Component({
  template: '',
})
export abstract class BaseTableSetComponent implements OnDestroy {

  @Input()
  public isLoading!: boolean;

  @Output()
  public onSearch: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public isAnySelectedChange!: EventEmitter<boolean>;

  @Output()
  public onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

  public tableConfig: TableConfigModel = new TableConfigModel();
  public emptyText!: IEmptyState;
  public isSelectedRow!: boolean;
  public isAllSelected!: boolean;
  public subscribers: Subscription[] = [];
  public iconTable = iconLib;
  public countSelectedRows: IAssociationDocument[] = [];

  public initTableConfig(dataKey: string[], dataLabel: string[]): void {
    this.subscribers = [];
    this.tableConfig = new TableConfigModel();
    this.tableConfig.dataKey = dataKey;

    this.tableConfig.dataLabel = dataLabel;
    this.tableConfig.dataType["selection"] = "custom";
    this.tableConfig.generateDataType();
    this.tableConfig.dataType["date"] = "M/d/yyyy";
    this.tableConfig.dataType["amount"] = "currency";
  }

  ngOnDestroy(): void {
    this.subscribers.forEach(each => each.unsubscribe())
  }

  public selectAllData(isChecked: boolean) {
    this.tableConfig.dataSource.forEach(
      (data) => (data.isSelected = isChecked),
    );

    this.isSelectedRow = this.tableConfig.dataSource.every(data => data.isSelected);
    this.getSelectedDocument();
  }

  public onCheckboxChange() {
    this.isAllSelected = !this.tableConfig.dataSource.some(
      (each) => !each.isSelected,
    );

    this.isSelectedRow = this.tableConfig.dataSource.some(
      (each) => each.isSelected,
    );

    this.getSelectedDocument();
  }

  public selectedRowHandler(row: IAssociationDocument) {
    if (row.isSelected) {
      this.countSelectedRows.push(row);
      return;
    }

    this.countSelectedRows = this.countSelectedRows.filter((t) => t.id !== row.id);
    this.isAllSelected = this.tableConfig.dataSource.every(
      (element) => element.isSelected,
    );
  }

  public onMouseOverChange(data: any) {
    this.tableConfig.dataSource.forEach((each) => {
      each.isHover = each.id === data?.id;

      if(isMobileOrTablet()) {
        each.isHover = true;
      }
    });
  }

  public onShowCheckbox(): void {
    this.tableConfig.dataSource.forEach(each => {
      each.isHover = !each.isHover
    })
  }

  public setCustomKey(keys: string[]): void {
    keys.forEach(key => {
      this.tableConfig.dataType[key] = 'custom';
    })
  }

  public selectedRow(selected: boolean) {
    this.isSelectedRow = selected;
  }

  public getSelectedDocument(): void {
    this.countSelectedRows = this.tableConfig.dataSource.filter((data: any) => data.isSelected);
  }

}
