<div class="dialog-header">
  <h2 mat-dialog-title>
    {{ dialogTitle }}
  </h2>
  <app-roam-button
    [class]="'btn btn-o-black flat exit-button'"
    [label]="'Save & Exit'"
    [isLoading]="loaders.submitting()"
    [disabled]="loaders.submitting()"
    (onClick)="onSave(true)" />
</div>

<mat-dialog-content class="dialog-content">
  <form [formGroup]="form" class="dialog-form">
    <div class="roam-form-row">
      <div class="roam-w-1/3">
        <div
          class="roam-form-field roam-w-full"
          [class.roam-form-field-error]="fieldError('customerId')">
          <app-roam-select
            className="radius-10"
            formControlName="customerId"
            placeholder="Select a customer account"
            [loading]="opts.customers.isLoading()"
            [items]="opts.customers.data()"
            [searchable]="true" />
          <p class="roam-form-error-message">This field is required</p>
        </div>
      </div>
      <div class="roam-w-1/3">
        <div
          class="roam-form-field roam-w-full"
          [class.roam-form-field-error]="fieldError('depositAccountId')">
          <app-roam-select
            placeholder="Payment Account"
            formControlName="depositAccountId"
            [items]="opts.paymentAccounts.data()" />
          <p class="roam-form-error-message">This field is required</p>
        </div>
      </div>
      <div class="roam-w-1/3">
        <div
          class="roam-form-field roam-w-full"
          [class.roam-form-field-error]="fieldError('paymentMethodId')">
          <app-roam-select
            placeholder="Payment Method"
            formControlName="paymentMethodId"
            [items]="opts.paymentMethods.data()" />
          <p class="roam-form-error-message">This field is required</p>
        </div>
      </div>
    </div>

    <div class="roam-form-row">
      <div class="roam-w-1/3">
        <div
          class="roam-form-field roam-w-full"
          [class.roam-form-field-error]="fieldError('date')">
          <app-roam-datepicker name="Payment Date" formControlName="date" />
          <p class="roam-form-error-message">This field is required</p>
        </div>
      </div>
      <div class="roam-w-1/3">
        <div
          class="roam-form-field roam-w-full"
          [class.roam-form-field-error]="fieldError('referenceNumber')">
          <app-roam-input name="Ref No" formControlName="referenceNumber" />
          <p class="roam-form-error-message">This field is required</p>
        </div>
      </div>
      <div class="roam-w-1/3">
        <div class="roam-form-field roam-w-full">
          <app-roam-input
            [currency]="true"
            formControlName="totalAmount"
            name="Total Amount" />
        </div>
      </div>
    </div>

    <!-- TODO: INSPECT UNPAID INVOICES TABLE, how does it work? -->

    <div class="roam-form-row">
      <div class="memo-wrapper">
        <h3 class="tc-black fw-500 pb-12">Additional Information</h3>
        <mat-form-field appearance="outline">
          <mat-label>Add Memo</mat-label>
          <textarea
            matInput
            formControlName="memo"
            [maxlength]="memoMaxLength"
            placeholder="Add Memo"></textarea>
        </mat-form-field>
        <div class="counter-text">
          {{ controls.memo.value.length || 0 }} / 255
        </div>
      </div>
    </div>

    <div class="roam-form-row">
      <div class="attachments-wrapper roam-w-full">
        <mat-label class="font-14px tc-black fw-500">Attachments</mat-label>
        <app-file-uploader [(data)]="attachments" [config]="modelConfig()" />
      </div>
    </div>

    <p>
      {{ totalAmount() | currency }}
    </p>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <a class="cancel-button" (click)="close()" cdkFocusInitial>
    <span class="label">Close</span>
  </a>
  <button mat-flat-button class="confirm-button" (click)="onSave()">
    Save
  </button>
</mat-dialog-actions>
